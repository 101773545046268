import { Component, OnInit } from "@angular/core";
import {
  Validators,
  FormGroup,
  FormControl,
  FormBuilder,
} from "@angular/forms";

import { Router } from "@angular/router";
// import { AuthService as AS } from "@auth0/auth0-angular";
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  isSubmitting: Boolean = false;
  processing: Boolean = false;
  error: Boolean = false;
  showAuthToken: boolean = false;
  visibleIcon: boolean = true;
  username: string;
  password: string;
  errorMessage: string;
  loggedCheck: string;

  loginForm: FormGroup = new FormGroup({
    username: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
  });
  get fc() {
    return this.loginForm.controls;
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder
  ) {
    this.loginForm = this.fb.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {}

  async onSubmit() {
    if (this.loginForm.valid) {
      this.processing = true;
      const { username, password } = this.loginForm.value;
      const errorMessage = await this.authService.login(username, password);
      if (errorMessage === null) {
        this.processing = false;
        this.error = false;
        setTimeout(() => {
          this.authService.navigateUser();
        }, 100);
      } else {
        this.processing = false;
        this.error = true;
        this.errorMessage = errorMessage;
      }
    }
  }
  togglePasswordVisibility() {
    this.visibleIcon = !this.visibleIcon;
    this.showAuthToken = !this.showAuthToken;
  }

  verifyPassword() {
    this.errorMessage = "";
  }
}
