import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { EChartsOption } from "echarts";
import { ActivatedRoute, Router } from "@angular/router";
import { LayoutService } from "src/app/services/layout.service";
import { StoreInsightService } from "src/app/services/store-insight.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HeatMapContainerPopupComponent } from "../heat-map/heat-map-container-popup/heat-map-container-popup.component";
import { PathwayContainerPopupComponent } from "./pathway/pathway-container-popup/pathway-container-popup.component";
import jwt_decode from "jwt-decode";
import { OwlOptions } from "ngx-owl-carousel-o";
import { AuthorizationService } from "src/app/services/authorization.service";
import dayjs from 'dayjs/esm';

@Component({
  selector: "app-store-insight",
  templateUrl: "./store-insight.component.html",
  styleUrls: ["./store-insight.component.scss"],
})
export class StoreInsightComponent {
  // @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["<", ">"],
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
  };

  storeAlertsBarChart: EChartsOption = {};
  storeAlertsBarChart1: EChartsOption = {};
  storeAlertsPieChart: EChartsOption = {};
  storeAlertsChartData: any;
  isStoreAlertsDisplay: boolean = false;

  storeAssistanceBarChart: EChartsOption = {};
  storeAssistanceBarChart1: EChartsOption = {};
  storeAssistancePieChart: EChartsOption = {};
  storeAssistanceChartData: any;
  isStoreAssistanceDisplay: boolean = false;

  storeFootfallBarChart: EChartsOption = {};
  storeFootfallBarChart1: EChartsOption = {};
  storeFootfallLineChart: EChartsOption = {};
  storeFootfallChartData: any;
  isStoreFootfallDisplay: boolean = false;

  storeOosPieChart: EChartsOption = {};
  storeOosBarChart: EChartsOption = {};
  storeOosBarChart1: EChartsOption = {};

  storeOosChartData: any;
  storeOosPieChartLoad: any;
  storeOosChartSaved: string;
  storeOosChartAtRisk: string;
  storeOosChartSavedValue: any;
  storeOosChartAtRiskValue: any;
  isStoreOosDisplay: boolean = false;

  storeReceptionPieChart: EChartsOption = {};
  storeReceptionLineChart: EChartsOption = {};
  storeReceptionChartData: any;
  storeReceptLineChartTitles: any;
  isStoreReceptionDisplay: boolean = false;

  storeLoiteringPieChart: EChartsOption = {};
  storeLoiteringBarChart: EChartsOption = {};
  storeLoiteringBarChart1: EChartsOption = {};

  storeLoiteringChartData: any;
  isStoreLoiteringDisplay: boolean = false;

  storeSelfCheckoutPieChart: EChartsOption = {};
  storeSelfCheckoutLineChart: EChartsOption = {};
  storeSelfCheckoutChartData: any;
  storeSelfCheckoutPieChartLoad: any;
  storeSelfCheckoutChartDataLables: any;
  storeSelfCheckoutSaved: string;
  storeSelfCheckoutAtRisk: string;
  storeSelfCheckoutSavedValue: any;
  storeSelfCheckoutAtRiskValue: any;
  isStoreSelfCheckoutDisplay: boolean = false;

  storeProtectionBarChart: EChartsOption = {};
  storeProtectionBarChart1: EChartsOption = {};

  storeProtectionPieChart: EChartsOption = {};
  storeProtectionChartData: any;
  storeProtectionPieChartLoad: any;
  storeProtectionChartSaved: string;
  storeProtectionChartAtRisk: string;
  storeProtectionChartSavedValue: any;
  storeProtectionChartAtRiskValue: any;
  isStoreProtectionDisplay: boolean = false;

  alertsDistributed: EChartsOption = {};
  inStockCompliance: EChartsOption = {};
  selfCheckoutChart: EChartsOption = {};
  customerReceptionChart: EChartsOption = {};
  isStorePosDisplay: boolean = false;

  displayEffectivenessChartData: any = {};
  displayEffectivenessPieChart: any = {};
  displayEffectivenessBarChart: any = {};

  checkoutQueueBarChart: EChartsOption = {};
  checkoutQueueLineChart: EChartsOption = {};
  checkoutQueueChartData: any = {};

  selectedCountry = [{countryName: "USA", countryUID:"1"}];
  selectedtState = [{stateName: "New York", stateUID:"1"}];
  selectedZipcode = [{zipCode: "100101", }];
  selectedStores: any = [{storeId: "d1e524a2-4406-43f7-a968-58542834c4c3", storeName: "Store 8"}];

  countriesList: any;
  statesList: any;
  zipCodesList: any;
  storesList: any;
  
  moreStoreAlerts: boolean = false;

  stockBarChartx: any;
  stockBarCharty: any;

  defaultCountry: string = "select";
  defaultState: string = "select";
  defaultZip: string = "select";
  defaultStore: string = "select";
  selectedStore: any = "7a9b5b2f-5f68-4f2b-8123-abcdef123456";
  
  country_id: number;
  state_id: number;
  zip_code: number;
  store_id: number;
  loggedUser: any;
  moreActveHours: boolean = false;
  storeProtectionActiveHours: boolean = false;
  storeAlertActivehours: boolean = false;
  storeOosActiveHours: boolean = false;
  storeLoiteringActiveHours: boolean = false;
  storeFootfallActiveHours: boolean = false;

  pathwayDataSet: any;
  pathwayModalRef: any;
  dashboardWidget: boolean = false;

  faceWidget = 1;
  dwellWidget = 2;
  oosWidget = 3;
  peopleWidget = 4;

  subscribedSolutions: any[] = [];
  filteredSolutions: any[] = [
    "OOS",
    "peoplecounter",
    "customerreception",
    "dwell",
  ];
  heatMapDataSet: any = [];
  colorArrayLegends: any = [];

  heatMapModalRef: any;
  cameraData: any = [];
  selectedCamera: any = "";
  heatMapCameraImage: any = "";

  storeName: any = "";
  currentTime: any = "";
  showHeatMapForSpeUser: boolean = false;
  autoRefereshStoreDataInterval: any;
  loading: boolean = false;
  decodedToken: any;
  isCountriesAllowed: boolean = false;
  isStatesAllowed: boolean = false;
  isZipCodeAllowed: boolean = false;
  isStoresAllowed: boolean = false;
  selectedDate: Date;

  activeHeatmapData: any;
  activeHeatmapResponse: any;

  lift_percentage: any = [
    { name: "<=1min" },
    { name: "1-5min" },
    { name: ">=5min" },
  ];

  constructor(
    private layoutService: LayoutService,
    private storeInsightService: StoreInsightService,
    private authorizationService: AuthorizationService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.loggedUser = localStorage.getItem("userRole");
  }

  ngOnInit(): void {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.decodedToken = jwt_decode(accessToken);
    this.isCountriesAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.countries, ['detail', '*']);
    this.isStatesAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.states, ['detail', '*']);
    this.isZipCodeAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.master, ['list', '*']);
    this.isStoresAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.stores, ['get-store-by-zipcode', '*']);
    
    if ( this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.tasks, ['list', '*']) ) {
      const pageTitle = "Store Insights";
      this.layoutService.setPageTitle(pageTitle);
      // this.getAuthorizationPermissions();
      this.configByRole();
      // this.autoRefresh();
      // this.toHideShowHeatMap();
    }
    else if(this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.orgs, ['list', '*']) 
      || this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.stores, ['list', '*'])) {
      this.router.navigateByUrl('/unauthorized');
    }
    // this.getCustomerHeatmapData();
  }

  toHideShowHeatMap() {
    /// here login_uid is user id checking static since we dont want heat map to be visible for all, not yet live.
    /// Need to remove this condition  "&& login_uid == af203c1f-82ab-4f2e-8016-6e45c4c85bb7" and its code related like flag used "showHeatMapForSpeUser"
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    const decodedToken: any = jwt_decode(accessToken);
    const login_uid = decodedToken?.login_uid;
    this.currentTime = new Date();
    if (login_uid == "af203c1f-82ab-4f2e-8016-6e45c4c85bb7") {
      this.showHeatMapForSpeUser = true;
    }
  }

  getCameraHeatMapData() {
    const useCaseIdentifier = "PeopleMovement";
    this.currentTime = new Date();
    this.cameraData = [];
    this.selectedCamera = "";
    this.heatMapCameraImage = "";
    this.storeName = "";
    this.heatMapDataSet = [];
    this.colorArrayLegends = [];
    if (this.selectedStore && this.selectedStore != "select") {
      this.getCameraDataForHeatMap(this.selectedStore, useCaseIdentifier);
    }
  }

  ngAfterViewInit() {
    if (this.loggedUser == "store-user") {
      this.configByRole();
    }
  }

  configByRole() {
    this.getCountries();
    if (this.selectedStore) {
      // this.getAlertDistributed();
      // this.getInStockCompliance();
      // this.getSelfCheckoutScanInfo();
      // this.getCustomerReceptionChart();
      // this.getAssistanceChartApi();
      // this.getFootfallChartsApi();
      // this.getInStockComplianceApi();
      // this.getCustomerReceptionApi();
      // this.getLoiteringChartApi();
      // this.getStoreAlertData();
      // this.getStoreProtectionData();
      // this.getSelfCheckoutApi();
      // this.getDisplayEffectivenessInfo();
    }
  }

  ngOnDestroy() {
    clearInterval(this.autoRefereshStoreDataInterval);
  }

  autoRefresh(): void {
    this.autoRefereshStoreDataInterval = setInterval(() => {
      if (this.selectedStore) {
        this.getAssistanceChartApi();
        this.getFootfallChartsApi();
        this.getInStockComplianceApi();
        this.getCustomerReceptionApi();
        this.getLoiteringChartApi();
        this.getStoreAlertData();
        this.getStoreProtectionData();
        this.getSelfCheckoutApi();
      }
    }, 15000);
  }

  datesUpdated(event: any): void {
    this.selectedDate = event.startDate ? event.startDate.format('YYYY-MM-DD') : '2000-01-01';
    if(this.selectedDate) {
      this.getDisplayEffectivenessInfo();
      this.getCheckoutQueueInfo();
      this.getCustomerHeatmapData();
    }
  }

  getCustomerHeatmapData() {
    this.storeInsightService.getActiveROIHeatmapInfo(this.selectedDate).subscribe((resp: any) => {
      if(resp){
        this.activeHeatmapData = resp;
        this.activeHeatmapResponse = {
          selectedDate: this.selectedDate,
          activeHeatmapData: this.activeHeatmapData
        };
      }
    })
  }

  getAuthorizationPermissions(){
    if(this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.["store-insights"], ['alert', '*']) ){
      this.isStoreAlertsDisplay = true;
    }
    if(this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.["store-insights"], ['assetprotection', '*']) ){
      this.isStoreProtectionDisplay = true;
    }
    if(this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.["store-insights"], ['customer_assistance', '*']) ){
      this.isStoreAssistanceDisplay = true;
    }
    if(this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.["store-insights"], ['customer_reception', '*']) ){
      this.isStoreReceptionDisplay = true;
    }
    if(this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.["store-insights"], ['footfall', '*']) ){
      this.isStoreFootfallDisplay = true;
    }
    if(this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.["store-insights"], ['oos', '*']) ){
      this.isStoreOosDisplay = true;
    }
    if(this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.["store-insights"], ['selfcheckout', '*']) ){
      this.isStoreSelfCheckoutDisplay = true;
    }
    if(this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.["store-insights"], ['loitering', '*']) ){
      this.isStoreLoiteringDisplay = true;
    }
    if(this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.["store-insights"], ['solutions', '*']) ){
      this.isStorePosDisplay = true;
    }
  }

  // Auth token need to be passed to "subscribedSolutions"
  isUCExists(usecase: string): boolean {
    return this.filteredSolutions.includes(usecase);
  }

  getCustomerReceptionChart() {
    this.customerReceptionChart = {
      color: ["#cd128a", "#ed8034", "#8c23a7"],
      series: [
        {
          name: "Today's Alerts",
          type: "pie",
          radius: ["35%", "90%"],
          avoidLabelOverlap: false,
          width: 100,
          height: 100,
          label: {
            show: true,
            position: "inner",
            color: "#fff",
            fontWeight: "bold",
          },
          data: [
            {
              value: 21,
              name: "46.7%",
              label: {
                fontSize: "9px",
              },
            },
            {
              value: 9,
              name: "20%",
              label: {
                fontSize: "9px",
              },
            },
            {
              value: 15,
              name: "33.3%",
              label: {
                fontSize: "9px",
              },
            },
          ],
        },
      ],
    };
  }

  getAlertDistributed() {
    this.alertsDistributed = {
      xAxis: {
        type: "category",
        data: [
          "8h:00",
          "9h:00",
          "10h:00",
          "11h:00",
          "12h:00",
          "1h:00",
          "2h:00",
          "3h:00",
          "4h:00",
          "5h:00",
          "6h:00",
          "7h:00",
        ],
        axisLabel: {
          interval: 0,
          rotate: 30,
          fontSize: 8,
          fontWeight: "bold",
        },
      },
      yAxis: {
        show: false,
      },

      height: "40",
      width: "90%",
      series: [
        {
          label: {
            show: true,
            position: "top",
          },
          barWidth: 8,
          itemStyle: {
            color: "#8e24aa",
            borderRadius: [50, 50, 0, 0],
          },
          markPoint: {
            data: [
              {
                type: "max",
                name: "Max",
                symbol: "circle",
                y: "35%",
                symbolSize: 20,
                label: {
                  fontSize: 10,
                },
              },
            ],
          },
          data: [72, 55, 48, 37, 39, 29, 43, 60, 75],
          type: "bar",
        },
      ],
    };
  }

  getInStockCompliance() {
    this.inStockCompliance = {
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["80%", "95%"],
          // center: ['50%', '70%'],
          // adjust the start angle
          width: 260,
          height: 150,
          startAngle: 180,
          // roseType: "bar",
          label: {
            show: false,
          },
          // label: {
          //   show: false,
          // },
          data: [
            {
              value: 60,
              name: "Search Engine",
              itemStyle: {
                color: "#9e20a2",
              },
            },
            {
              value: 40,
              name: "Direct",
              itemStyle: {
                color: "#dd4761",
              },
            },
            {
              // make an record to fill the bottom 50%
              value: 60 + 40,
              itemStyle: {
                // stop the chart from rendering this piece
                color: "none",
              },
              label: {
                show: false,
              },
            },
          ],
        },
      ],
    };
  }

  getSelfCheckoutScanInfo() {
    this.selfCheckoutChart = {
      xAxis: {
        type: "category",
        data: ["D-7", "D-6", "D-5", "D-4", "D-3", "D-2", "D-1", "Today"],
        axisLabel: {
          interval: 0,

          fontSize: 8,
          fontWeight: "bold",
        },
      },
      yAxis: {
        type: "value",
        show: false,
      },
      width: "90%",
      height: 100,
      series: [
        {
          data: [3, 2, 1, 1, 1, 5, 3, 7],
          type: "line",
          lineStyle: {
            color: "#9e20a2",
            width: 2,
            type: "dashed",
          },
          label: {
            show: true,
          },
          itemStyle: {
            color: "#9e20a2",
          },
        },
        {
          data: [15, 13, 7, 12, 13, 13, 14, 13],
          type: "line",
          lineStyle: {
            color: "#dd4761",
            width: 2,
            type: "solid",
          },
          label: {
            show: true,
          },
          itemStyle: {
            color: "#dd4761",
          },
        },
      ],
    };
  }

  getCountries() {
    // this.storeInsightService.getCountriesData().subscribe((resp: any) => {
    //   this.countriesList = resp.data;
    // });
    this.countriesList = this.selectedCountry;
  }

  // Selected country Function
  getStatesList(event: any) {
    // const selectedCountry = event.target.value;
    const selectedCountry = this.selectedCountry

    if (event.target.value === "select") {
      this.defaultState = "select";
      this.defaultZip = "select";
      this.defaultStore = "select";
      this.statesList = [];
      this.zipCodesList = [];
      this.storesList = [];
      return;
    }
    
    if (selectedCountry !== null) {
      this.defaultState = "select";
      this.defaultZip = "select";
      this.defaultStore = "select";
      this.statesList = this.selectedtState;
      // this.storeInsightService
      //   .getStatesData(selectedCountry)
      //   .subscribe((resp: any) => {
      //     this.statesList = resp.data;
      //   });
    }
  }

  // Selected state Function
  getZipCodesList(event: any) {
    // const selectedState = event.target.value;
    const selectedState = this.selectedtState;
    if (event.target.value === "select") {
      this.defaultZip = "select";
      this.defaultStore = "select";
      this.zipCodesList = [];
      this.storesList = [];
      return;
    }
    
    if (selectedState != null) {
      this.defaultZip = "select";
      this.defaultStore = "select";
      this.zipCodesList = this.selectedZipcode;
      // this.storeInsightService
      //   .getZipCodeData(selectedState)
      //   .subscribe((resp: any) => {
      //     this.zipCodesList = resp.data;
      //   });
    }
  }

  // Selected state Function
  getStoresList(event: any) {
    // const selectedZip = event.target.value;
    const selectedZip = this.selectedZipcode;
    if (event.target.value === "select") {
      this.defaultStore = "select";
      this.storesList = [];
      return;
    }
    if (selectedZip !== null) {
      this.defaultStore = "select";
      this.storesList = this.selectedStores;
      // this.storeInsightService
      //   .getStoresData(selectedZip)
      //   .subscribe((resp: any) => {
      //     this.storesList = resp.data;
      //     this.storesList = resp.data.sort((a: any, b: any) =>
      //       a.storeName.localeCompare(b.storeName)
      //     );
      //   });
    }
  }

  // Selected store Function
  fetchStoreData(event: any) {
    // this.selectedStore = this.selectedStore;
    if (this.selectedStore) {
      this.getAlertDistributed();
      this.getInStockCompliance();
      this.getSelfCheckoutScanInfo();
      this.getCustomerReceptionChart();

      // API DATA BELOW
      this.getAssistanceChartApi();
      this.getFootfallChartsApi();
      this.getInStockComplianceApi();
      this.getCustomerReceptionApi();
      this.getStoreAlertData();
      this.getStoreProtectionData();
      this.getSelfCheckoutApi();
      this.getLoiteringChartApi();

      // New Widgets Data
      this.getDisplayEffectivenessInfo();
      this.getCheckoutQueueInfo();
      // this.getCustomerHeatmapData();

      // Get the heat map and camera data
      if (this.showHeatMapForSpeUser) {
        this.getCameraHeatMapData();
      }
    }
  }

  // Alerts Api Integration Done
  getStoreAlertData() {
    this.storeInsightService
      .getWidgetData(this.selectedStore, "alert")
      .subscribe((res: any) => {
        this.storeAlertsChartData = res.data;
        var storeAlertPieChartLoad = res.data.today_alert;
        var storeAlertBarChartLoad = res.data.charts[0];

        const storeAlertBarChartxSeries = storeAlertBarChartLoad.xAxisSeries;
        var storeAlertBarChartData1 = storeAlertBarChartxSeries.slice(0, 12);

        var storeAlertBarChartData3 = storeAlertBarChartLoad.yAxisSeries.slice(
          0,
          12
        );

        var filteredData = storeAlertBarChartData3.filter(function (
          value: any
        ) {
          return value !== -1;
        });

        var maxIndex = storeAlertBarChartData3.indexOf(
          Math.max(...storeAlertBarChartData3)
        );

        var lastItemValue = storeAlertBarChartLoad.length - 1;
        var lastItemValue1 = storeAlertBarChartData1.length - 1;
        const values = [];

        for (let i = 0; i < storeAlertPieChartLoad.length; i++) {
          values.push(storeAlertPieChartLoad[i]?.value);
        }
        const total = values.reduce((sum, value) => sum + value, 0);
        const percentages = values.map((value) => (value / total) * 100);
        const roundedPercentages = percentages.map((percentage) =>
          parseFloat(percentage.toFixed(1))
        );

        this.storeAlertsPieChart = {
          tooltip: {
            trigger: "item",
            show: false,
          },
          series: [
            {
              name: "Today's Alerts",
              type: "pie",
              radius: ["35%", "90%"],
              avoidLabelOverlap: false,
              width: 100,
              height: 100,
              label: {
                show: true,
                position: "inner",
                color: "#fff",
                fontWeight: "bold",
              },

              data: [
                {
                  value: storeAlertPieChartLoad[0]?.value,
                  name: roundedPercentages[0] + "%",
                  label: {
                    fontSize: "8px",
                    // formatter: (params: any) => {
                    //   if (params.value !== 0) {
                    //     return params.name;
                    //   }
                    //   return "";
                    // },
                  },
                  itemStyle: {
                    color: "#cd128a",
                  },
                },
                {
                  value: storeAlertPieChartLoad[1]?.value,
                  name: roundedPercentages[1] + "%",
                  label: {
                    fontSize: "8px",
                    // formatter: (params: any) => {
                    //   if (params.value !== 0) {
                    //     return params.name;
                    //   }
                    //   return "";
                    // },
                  },
                  itemStyle: {
                    color: "#ed8034",
                  },
                },
                {
                  value: storeAlertPieChartLoad[2]?.value,
                  name: roundedPercentages[2] + "%",
                  label: {
                    fontSize: "8px",
                    formatter: (params: any) => {
                      if (
                        params.value !== 0 ||
                        params.value !== null ||
                        params.value !== undefined
                      ) {
                        return params.name;
                      }
                      return "";
                    },
                  },
                  itemStyle: {
                    color: "#8d23a9",
                  },
                },
                {
                  value: storeAlertPieChartLoad[3]?.value,
                  name: roundedPercentages[3] + "%",
                  label: {
                    fontSize: "8px",
                    formatter: (params: any) => {
                      if (
                        params.value !== 0 ||
                        params.value !== null ||
                        params.value !== undefined
                      ) {
                        return params.name;
                      }
                      return "";
                    },
                  },
                  itemStyle: {
                    color: "#dd4761",
                  },
                },
                {
                  value: storeAlertPieChartLoad[4]?.value,
                  name: roundedPercentages[4] + "%",
                  label: {
                    fontSize: "8px",
                    formatter: (params: any) => {
                      if (
                        params.value !== 0 ||
                        params.value !== null ||
                        params.value !== undefined
                      ) {
                        return params.name;
                      }
                      return "";
                    },
                  },
                  itemStyle: {
                    color: "#08b162",
                  },
                },
              ],
            },
          ],
        };

        this.storeAlertsBarChart = {
          xAxis: {
            type: "category",
            data: storeAlertBarChartData1.slice(
              0,
              storeAlertBarChartData3.length
            ),

            axisLabel: {
              interval: 0,
              rotate: 30,
              fontSize: 8,
              fontWeight: "bold",
            },
          },
          yAxis: {
            show: false,
          },

          height: "40",
          width: "90%",
          series: [
            {
              label: {
                show: true,
                position: "top",
                // formatter: function (params) {
                //   if (params?.value) {
                //     return params.value.toString();
                //   } else {
                //     return "";
                //   }
                // },
              },
              barWidth: 8,
              itemStyle: {
                borderRadius: [50, 50, 0, 0],
                color: function (params: any) {
                  if(storeAlertBarChartData4){
                    if (params.dataIndex === lastItemValue1) {
                  return "#8e24aa";
                }
              } else {
                if (params.dataIndex === lastItemValue) {
                  return "#8e24aa";
                }
              }

                  return "#d6afe0";
                },
              },
              markPoint: {
                data: [
                  {
                    type: "max",
                    name: "Max",
                    symbol: "circle",
                    y: "37%",
                    symbolSize: 22,
                    label: {
                      fontSize: 9,
                    },
                    itemStyle: {
                      color: "#d6afe0",
                    },
                  },
                ],
              },
              data: filteredData,
              type: "bar",
            },
          ],
        };

        var storeAlertBarChartData2 = storeAlertBarChartxSeries.slice(12);

        var storeAlertBarChartData4 =
          storeAlertBarChartLoad.yAxisSeries.slice(12);

          if (storeAlertBarChartxSeries.length <= 12) {
            this.storeAlertActivehours = false;
          } else {
            this.storeAlertActivehours = true;
          }

        if (storeAlertBarChartData4.length < 12) {          
          var filteredData1 = storeAlertBarChartData4.filter(function (
            value: any
          ) {
            return value !== -1;
          });
          var maxIndex1 = storeAlertBarChartData4.indexOf(
            Math.max(...storeAlertBarChartData4)
          );
         
          this.storeAlertsBarChart1 = {
            xAxis: {
              type: "category",
              data: storeAlertBarChartData2.slice(
                0,
                storeAlertBarChartData4.length
              ),
              axisLabel: {
                interval: 0,
                rotate: 30,
                fontSize: 8,
                fontWeight: "bold",
              },
            },
            yAxis: {
              show: false,
            },

            height: "40",
            width: "90%",
            series: [
              {
                label: {
                  show: true,
                  position: "top",
                  // formatter: function (params) {
                  //   if (params?.value) {
                  //     return params.value.toString();
                  //   } else {
                  //     return "";
                  //   }
                  // },
                },
                barWidth: 8,
                itemStyle: {
                  borderRadius: [50, 50, 0, 0],
                  // color: function (params) {
                  //   if (params.dataIndex === maxIndex1) {
                    color: function (params: any) {
                      if(storeAlertBarChartData4){
                        if (params.dataIndex === lastItemValue1) {
                      return "#8e24aa";
                    }
                  } else {
                    if (params.dataIndex === lastItemValue) {
                      return "#8e24aa";
                    }
                  }

                    return "#d6afe0";
                  },
                },
                markPoint: {
                  data: [
                    {
                      type: "max",
                      name: "Max",
                      symbol: "circle",
                      y: "37%",
                      symbolSize: 22,
                      label: {
                        fontSize: 9,
                      },
                      itemStyle: {
                        color: "#d6afe0",
                      },
                    },
                  ],
                },
                data: filteredData1,
                type: "bar",
              },
            ],
          };
        }
      });
  }

  // Customer Footfall Api Integration Done
  getFootfallChartsApi() {
    this.storeInsightService
      .getStoreFootfallData(this.selectedStore)
      .subscribe((res: any) => {
        this.storeFootfallChartData = res.data;

        // var storeFootfallChartData = res.data.today_alert;
        var storeFootFallBarChartLoad = res.data.charts[0];
        const storeFootFallBarChartxSeries =
          storeFootFallBarChartLoad.xAxisSeries;
        // const storeFootFallBarChartxSeries = [
        //   "6AM",
        //   "7AM",
        //   "8AM",
        //   "9AM",
        //   "10AM",
        //   "11AM",
        //   "12PM",
        //   "1PM",
        //   "2PM",
        //   "3PM",
        //   "4PM",
        //   "5PM",
        //   "6PM",
        //   "7PM",
        //   "8PM",
        //   "9PM",
        //   "10PM",
        //   "11PM",
        //   "12AM",
        //   "1AM",
        //   "2AM",
        //   "3AM",
        //   "4AM",
        //   "5AM",
        // ];
        var storeFootFallBarChartData1 = storeFootFallBarChartxSeries.slice(
          0,
          12
        );

        var storeFootFallBarChartData3 =
          storeFootFallBarChartLoad.yAxisSeries.slice(0, 12);

        const barChartLoad = this.storeFootfallChartData?.charts[0];
        const lineChartLoad = this.storeFootfallChartData?.charts[1];

        var filteredData = storeFootFallBarChartData3.filter(function (
          value: any
        ) {
          return value !== -1;
        });

        var maxIndex = storeFootFallBarChartData3.indexOf(
          Math.max(...storeFootFallBarChartData3)
        );
        var lastItemValue = storeFootFallBarChartLoad.length - 1;
        var lastItemValue1 = storeFootFallBarChartData1.length - 1;

        this.storeFootfallBarChart = {
          xAxis: {
            type: "category",
            data: storeFootFallBarChartData1.slice(
              0,
              storeFootFallBarChartData3.length
            ),
            axisLabel: {
              interval: 0,
              rotate: 30,
              fontSize: 8,
              fontWeight: "bold",
            },
          },
          yAxis: {
            show: false,
          },
          height: "40",
          width: "90%",
          series: [
            {
              label: {
                show: true,
                position: "top",
                // formatter: function (params) {
                //   if (params?.value) {
                //     return params.value.toString();
                //   } else {
                //     return "";
                //   }
                // },
              },
              barWidth: 8,
              itemStyle: {
                borderRadius: [50, 50, 0, 0],
                color: function (params: any) {
                  if(storeFootFallBarChartData4){
                    if (params.dataIndex === lastItemValue1) {
                  return "#8e24aa";
                }
                } 
                else{
                  if (params.dataIndex === lastItemValue1) {
                    return "#8e24aa";
                  }
                }

                  return "#d6afe0";
                },
              },
              markPoint: {
                data: [
                  {
                    type: "max",
                    name: "Max",
                    symbol: "circle",
                    y: "37%",
                    symbolSize: 20,
                    label: {
                      fontSize: 10,
                    },
                    itemStyle: {
                      color: "#d6afe0",
                    },
                  },
                ],
              },
              data: filteredData,
              type: "bar",
            },
          ],
        };

        var storeFootFallBarChartData2 = storeFootFallBarChartxSeries.slice(12);

        var storeFootFallBarChartData4 =
          storeFootFallBarChartLoad.yAxisSeries.slice(12);

          if (storeFootFallBarChartxSeries.length <= 12) {
            this.storeFootfallActiveHours = false;
          } else {
            this.storeFootfallActiveHours = true;
          }        

        if (storeFootFallBarChartData4) {
          var filteredData1 = storeFootFallBarChartData4.filter(function (
            value: any
          ) {
            return value !== -1;
          });
          var maxIndex1 = storeFootFallBarChartData4.indexOf(
            Math.max(...storeFootFallBarChartData4)
          );
          this.storeFootfallBarChart1 = {
            xAxis: {
              type: "category",
              data: storeFootFallBarChartData2.slice(
                0,
                storeFootFallBarChartData4.length
              ),
              axisLabel: {
                interval: 0,
                rotate: 30,
                fontSize: 8,
                fontWeight: "bold",
              },
            },
            yAxis: {
              show: false,
            },
            height: "40",
            width: "90%",
            series: [
              {
                label: {
                  show: true,
                  position: "top",
                  // formatter: function (params) {
                  //   if (params?.value) {
                  //     return params.value.toString();
                  //   } else {
                  //     return "";
                  //   }
                  // },
                },
                barWidth: 8,
                itemStyle: {
                  borderRadius: [50, 50, 0, 0],
                  // color: function (params) {
                  //   if (params.dataIndex === maxIndex1) {
                    color: function (params: any) {
                      if(storeFootFallBarChartData4){
                        if (params.dataIndex === lastItemValue1) {
                      return "#8e24aa";
                    }
                    } 
                    else{
                      if (params.dataIndex === lastItemValue1) {
                        return "#8e24aa";
                      }
                    }

                    return "#d6afe0";
                  },
                },
                markPoint: {
                  data: [
                    {
                      type: "max",
                      name: "Max",
                      symbol: "circle",
                      y: "37%",
                      symbolSize: 20,
                      label: {
                        fontSize: 10,
                      },
                      itemStyle: {
                        color: "#d6afe0",
                      },
                    },
                  ],
                },
                data: filteredData1,
                type: "bar",
              },
            ],
          };
        }
        this.storeFootfallLineChart = {
          xAxis: {
            type: "category",
            // data: lineChartLoad.xAxisSeries,
            data: ["D-7", "D-6", "D-5", "D-4", "D-3", "D-2", "D-1", "TODAY"],
            axisLabel: {
              interval: 0,
              fontSize: 8,
              fontWeight: "bold",
              formatter: function (value: any) {
                return value.charAt(0).toUpperCase() + value.slice(1);
              },
            },
          },
          yAxis: {
            type: "value",
            show: false,
          },
          width: "90%",
          height: 50,
          color: "#dd4761",
          series: [
            {
              data: lineChartLoad.yAxisSeries,
              type: "line",
              label: {
                show: true,
              },
            },
          ],
        };
      });
  }

  // In Stock Api Integration Done
  getInStockComplianceApi() {
    this.storeInsightService
      .getWidgetData(this.selectedStore, "oos")
      .subscribe((res: any) => {
        this.storeOosChartData = res.data;

        // Pie chart data
        this.storeOosPieChartLoad = this.storeOosChartData?.charts[1];
        this.storeOosChartSaved = this.storeOosPieChartLoad?.xAxisSeries[0];
        this.storeOosChartAtRisk = this.storeOosPieChartLoad?.xAxisSeries[1];
        this.storeOosChartSavedValue =
          this.storeOosPieChartLoad?.yAxisSeries[0];
        this.storeOosChartAtRiskValue =
          this.storeOosPieChartLoad?.yAxisSeries[1];

        this.storeOosBarChart = this.storeOosChartData?.charts[0];

        const stockComplianceBarChartLoadx =
          this.storeOosChartData?.charts[0]?.xAxisSeries;
        const stockComplianceBarChartLoady =
          this.storeOosChartData?.charts[0]?.yAxisSeries;

        var stockComplianceBarChartData1 = stockComplianceBarChartLoadx.slice(
          0,
          12
        );

        var stockComplianceBarChartData2 =
          stockComplianceBarChartLoadx.slice(12);

        var stockComplianceBarChartData3 = stockComplianceBarChartLoady.slice(
          0,
          12
        );
        var stockComplianceBarChartData4 =
          stockComplianceBarChartLoady.slice(12);

        var filteredBarData = stockComplianceBarChartData3.filter(function (
          value: any
        ) {
          return value !== -1;
        });

        const maxIndex = stockComplianceBarChartData3.indexOf(
          Math.max(...stockComplianceBarChartData3)
        );

        var lastItemValue = stockComplianceBarChartLoady.length - 1;
        var lastItemValue1 = stockComplianceBarChartData1.length - 1;

        this.storeOosBarChart = {
          xAxis: {
            type: "category",
            data: stockComplianceBarChartData1,
            axisLabel: {
              interval: 0,
              rotate: 30,
              fontSize: 8,
              fontWeight: "bold",
            },
          },
          yAxis: {
            show: false,
          },

          height: "40",
          width: "90%",
          series: [
            {
              label: {
                show: true,
                position: "top",
                // formatter: function (params: any) {
                //   if (params?.value) {
                //     return params.value.toString();
                //   } else {
                //     return "";
                //   }
                // },
              },
              barWidth: 8,
              itemStyle: {
                borderRadius: [50, 50, 0, 0],
                color: function (params: any) {
                  // if (lastItemValue) {
                  //   if (params.dataIndex === lastItemValue) {
                  //     return "#8e24aa";
                  //   }
                  // } else {
                  //   if (params.dataIndex === maxIndex) {
                  //     return "#8e24aa";
                  //   }
                  // }

                  if (stockComplianceBarChartData4) {
                    if (params.dataIndex === lastItemValue1) {
                      return "#8e24aa";
                    }
                  } else {
                    if (params.dataIndex === lastItemValue) {
                      return "#8e24aa";
                    }
                  }
                  return "#d6afe0";
                },
              },
              markPoint: {
                data: [
                  {
                    type: "max",
                    name: "Max",
                    symbol: "circle",
                    y: "37%",
                    symbolSize: 20,
                    label: {
                      fontSize: 10,
                    },
                    itemStyle: {
                      color: "#d6afe0",
                    },
                  },
                ],
              },
              data: filteredBarData,
              type: "bar",
            },
          ],
        };

        if (stockComplianceBarChartLoady.length <= 12) {
          this.storeOosActiveHours = false;
        } else {
          this.storeOosActiveHours = true;
        }

        if (stockComplianceBarChartData4) {
          var filteredBarData1 = stockComplianceBarChartData4.filter(function (
            value: any
          ) {
            return value !== -1;
          });
          var maxIndex1 = stockComplianceBarChartData4.indexOf(
            Math.max(...stockComplianceBarChartData4)
          );

          var lastItemValue2 = stockComplianceBarChartData4.length - 1;
          this.storeOosBarChart1 = {
            xAxis: {
              type: "category",
              data: stockComplianceBarChartData2,
              axisLabel: {
                interval: 0,
                rotate: 30,
                fontSize: 8,
                fontWeight: "bold",
              },
            },
            yAxis: {
              show: false,
            },

            height: "40",
            width: "90%",
            series: [
              {
                label: {
                  show: true,
                  position: "top",
                  // formatter: function (params) {
                  //   if (params?.value) {
                  //     return params.value.toString();
                  //   } else {
                  //     return "";
                  //   }
                  // },
                },
                barWidth: 8,
                itemStyle: {
                  borderRadius: [50, 50, 0, 0],
                  color: function (params) {
                    if (params.dataIndex === lastItemValue2) {
                      return "#8e24aa";
                    }
                    return "#d6afe0";
                  },
                },
                markPoint: {
                  data: [
                    {
                      type: "max",
                      name: "Max",
                      symbol: "circle",
                      y: "37%",
                      symbolSize: 20,
                      label: {
                        fontSize: 10,
                      },

                      itemStyle: {
                        color: "#d6afe0",
                      },
                    },
                  ],
                },
                data: filteredBarData1,
                type: "bar",
              },
            ],
          };
        }
        this.storeOosPieChart = {
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: ["80%", "95%"],
              width: 260,
              height: 150,
              startAngle: 180,
              // roseType: "radius",
              label: {
                show: false,
              },
              data: [
                {
                  value: this.storeOosChartSavedValue,
                  name: "Saved",
                  itemStyle: {
                    color: "#9e20a2",
                  },
                },
                {
                  value: this.storeOosChartAtRiskValue,
                  name: "At Risk",
                  itemStyle: {
                    color: "#dd4761",
                  },
                },
                {
                  // make an record to fill the bottom 50%
                  value:
                    this.storeOosChartSavedValue +
                    this.storeOosChartAtRiskValue,
                  itemStyle: {
                    // stop the chart from rendering this piece
                    color: "none",
                  },
                  label: {
                    show: false,
                  },
                },
              ],
            },
          ],
        };
      });
  }


  getDisplayEffectivenessInfo() {
    this.storeInsightService.getDisplayEffectivenessData(this.selectedDate).subscribe((resp: any) => {
      this.displayEffectivenessChartData = resp.data;
      const values = [
        this.displayEffectivenessChartData?.display_types[0]?.value,
        this.displayEffectivenessChartData?.display_types[1]?.value,
        this.displayEffectivenessChartData?.display_types[2]?.value,
      ];
      const total = values.reduce((sum, value) => sum + value, 0);
      const percentages = values.map((value) => (value / total) * 100);
      const roundedPercentages = percentages.map((percentage) =>
        parseFloat(percentage.toFixed(1))
      );

      this.displayEffectivenessPieChart = {
        tooltip: {
          trigger: "item",
          show: false,
        },
        series: [
          {
            name: "Today's Alerts",
            type: "pie",
            radius: ["35%", "90%"],
            avoidLabelOverlap: false,
            width: 100,
            height: 100,
            label: {
              show: true,
              position: "inner",
              color: "#fff",
              fontWeight: "bold",
            },
            // data: storeAlertPieChartLoad,
            data: [
              {
                value: this.displayEffectivenessChartData?.display_types[0]?.value,
                name: roundedPercentages[0] + "%",
                label: {
                  fontSize: "8px",
                  formatter: (params: any) => {
                    if (params.value !== 0) {
                      return params.name;
                    }
                    return "";
                  },
                },
                itemStyle: {
                  color: "#cd128a",
                },
              },
              {
                value: this.displayEffectivenessChartData?.display_types[1]?.value,
                name: roundedPercentages[1] + "%",
                label: {
                  fontSize: "8px",
                  formatter: (params: any) => {
                    if (params.value !== 0) {
                      return params.name;
                    }
                    return "";
                  },
                },
                itemStyle: {
                  color: "#ed8034",
                },
              },
              {
                value: this.displayEffectivenessChartData?.display_types[2]?.value,
                name: roundedPercentages[2] + "%",
                label: {
                  fontSize: "8px",
                  formatter: (params: any) => {
                    if (params.value !== 0) {
                      return params.name;
                    }
                    return "";
                  },
                },
                itemStyle: {
                  color: "#8d23a9",
                },
              },
            ],
          },
        ],
      };

      interface StackInfo {
        stackStart: number[];
        stackEnd: number[];
      }
      // Bar chart configuration
      const series: {
        data: number[];
        type: string;
        stack: string;
        barWidth: string;
        itemStyle: { color: string };
        label?: {
          show: boolean;
          position: string;
          fontSize: number;
          color: string;
          formatter?: (params: any) => number;
          offset?: [number, number];
        };
        tooltip?: { show: boolean };
      }[] = [
        {
          data: this.displayEffectivenessChartData?.charts[0].yAxisSeries[0].data,
          type: "bar",
          stack: "a",
          barWidth: "40%",
          itemStyle: {
            color: "#cd128a",
          },
        },
        {
          data: this.displayEffectivenessChartData?.charts[0].yAxisSeries[1].data,
          type: "bar",
          stack: "a",
          barWidth: "40%",
          itemStyle: {
            color: "#ee8134",
          },
        },
        {
          data: this.displayEffectivenessChartData?.charts[0].yAxisSeries[2].data,
          type: "bar",
          stack: "a",
          barWidth: "40%",
          itemStyle: {
            color: "#8722a2",
          },
        },
      ];

      const stackInfo: { [key: string]: StackInfo } = {};
      const totalValues = Array(series[0].data.length).fill(0);

      for (let i = 0; i < series[0].data.length; ++i) {
        for (let j = 0; j < series.length; ++j) {
          const stackName = series[j].stack;
          if (!stackName) {
            continue;
          }
          if (!stackInfo[stackName]) {
            stackInfo[stackName] = {
              stackStart: [],
              stackEnd: [],
            };
          }
          const info = stackInfo[stackName];
          const data = series[j].data[i];
          if (typeof data === "number" && data !== 0) {
            if (info.stackStart[i] == null) {
              info.stackStart[i] = j;
            }
            info.stackEnd[i] = j;
            totalValues[i] += data;
          }
        }
      }

      for (let i = 0; i < series.length; ++i) {
        const data = series[i].data as
          | number[]
          | { value: number; itemStyle: object }[];
        const info = stackInfo[series[i].stack];
        for (let j = 0; j < series[i].data.length; ++j) {
          const isEnd = info.stackEnd[j] === i;
          const topBorder = isEnd ? 100 : 0;
          const bottomBorder = 0;
          data[j] = {
            value: data[j] as number,
            itemStyle: {
              borderRadius: [topBorder, topBorder, bottomBorder, bottomBorder],
            },
          };
        }
      }

      // Add a new series for total labels
      series.push({
        data: totalValues,
        type: "bar",
        stack: "a",
        barWidth: "40%",
        itemStyle: {
          color: "transparent",
        },
        label: {
          show: true,
          position: "bottom",
          fontSize: 12,
          color: "#000",
          offset: [0, -20],
          formatter: function (params) {
            return totalValues[params.dataIndex];
          },
        },
        tooltip: {
          show: false,
        },
      });

      this.displayEffectivenessBarChart = {
        xAxis: {
          type: "category",
          data: this.displayEffectivenessChartData?.charts[0].xAxisSeries,
          axisLabel: {
            rotate: 60,
            fontSize: 8,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: series,
      };
    });
  }

  getCheckoutQueueInfo() {
    this.storeInsightService.getCustomerAnalyticsData(this.selectedDate).subscribe((resp: any) => {
      this.checkoutQueueChartData = resp.data
      const barChartLoad = this.checkoutQueueChartData?.charts[0];
      var barChartFilteredData = barChartLoad.yAxisSeries.filter(function (value: any) {
        return value !== -1;
      });
      
      var maxIndex = barChartLoad.yAxisSeries.indexOf(
        Math.max(...barChartLoad.yAxisSeries)
      );

      this.checkoutQueueBarChart = {
        xAxis: {
          type: "category",
          data: barChartLoad.xAxisSeries,
          axisLabel: {
            interval: 0,
            rotate: 30,
            fontSize: 8,
            fontWeight: "bold",
          },
        },
        yAxis: {
          show: false,
        },
        height: "40",
        width: "90%",
        series: [
          {
            label: {
              show: true,
              position: "top",
              formatter: function (params: any) {
                if (params?.value) {
                  return params.value.toString();
                } else {
                  return "";
                }
              },
            },
            barWidth: 8,
            itemStyle: {
              borderRadius: [50, 50, 0, 0],
              color: function (params: any) {
                if (params.dataIndex === maxIndex) {
                  return "#8e24aa";
                }
                return "#d6afe0";
              },
            },
            markPoint: {
              data: [
                {
                  type: "max",
                  name: "Max",
                  symbol: "circle",
                  y: "37%",
                  symbolSize: 20,
                  label: {
                    fontSize: 10,
                  },
                  itemStyle: {
                    color: "#8e24aa",
                  },
                },
              ],
            },
            data: barChartFilteredData,
            type: "bar",
          },
        ],
      };

      // Line chart Initialization
      const lineChartLoad = this.checkoutQueueChartData?.charts[1];
      this.checkoutQueueLineChart = {
        xAxis: {
          type: "category",
          data: lineChartLoad.xAxisSeries,
          axisLabel: {
            interval: 0,
            rotate: 30,
            fontSize: 8,
            fontWeight: "bold",
            formatter: function (value: any) {
              return value.charAt(0).toUpperCase() + value.slice(1);
            },
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        width: "90%",
        height: 36,
        color: "#dd4761",
        series: [
          {
            data: lineChartLoad.yAxisSeries,
            type: "line",
            label: {
              show: true,
            },
          },
        ],
      };
    });
  }

  // Asset Protection Api Integration Done
  getStoreProtectionData() {
    this.storeInsightService
      .getStoreAssetProtectionData(this.selectedStore)
      .subscribe((res: any) => {
        this.storeProtectionChartData = res.data;

        // var storeProtectionBarChartLoad =
        //   this.storeProtectionChartData?.charts[0];
        this.storeProtectionPieChartLoad =
          this.storeProtectionChartData?.charts[1];

        this.storeProtectionChartSaved =
          this.storeProtectionPieChartLoad?.xAxisSeries[0];
        this.storeProtectionChartAtRisk =
          this.storeProtectionPieChartLoad?.xAxisSeries[1];

        this.storeProtectionChartSavedValue = Number(
          this.storeProtectionPieChartLoad?.yAxisSeries[0]
        );
        this.storeProtectionChartAtRiskValue = Number(
          this.storeProtectionPieChartLoad?.yAxisSeries[1]
        );

        var storeProtectionBarChartLoad = res.data.charts[0];
        const storeProtectionBarChartxSeries =
          storeProtectionBarChartLoad.xAxisSeries;

        var storeProtectionBarChartData1 = storeProtectionBarChartxSeries.slice(
          0,
          12
        );
        var stockComplianceBarChartData3 =
          storeProtectionBarChartLoad.yAxisSeries.slice(0, 12);

        var filteredBarData = stockComplianceBarChartData3.filter(function (
          value: any
        ) {
          return value !== -1;
        });

        var maxIndex = stockComplianceBarChartData3.indexOf(
          Math.max(...stockComplianceBarChartData3)
        );

        var lastItemValue = storeProtectionBarChartLoad.length - 1;
        var lastItemValue1 = storeProtectionBarChartData1.length - 1;
        var storeProtectItemColor = "#8e24aa";
        if (stockComplianceBarChartData4) {
          storeProtectItemColor = "#d6afe0";
        }

        this.storeProtectionBarChart = {
          xAxis: {
            type: "category",
            // data: storeProtectionBarChartData1,
            data: storeProtectionBarChartData1.slice(
              0,
              stockComplianceBarChartData3.length
            ),

            axisLabel: {
              interval: 0,
              rotate: 30,
              fontSize: 8,
              fontWeight: "bold",
            },
          },
          yAxis: {
            show: false,
          },

          height: "40",
          width: "90%",
          series: [
            {
              label: {
                show: true,
                position: "top",
                // formatter: function (params) {
                //   if (params?.value) {
                //     return params.value.toString();
                //   } else {
                //     return "";
                //   }
                // },
              },
              barWidth: 8,
              itemStyle: {
                borderRadius: [50, 50, 0, 0],
                color: function (params: any) {
                  if(stockComplianceBarChartData4){
                  if (params.dataIndex === lastItemValue1) {
                    return "#8e24aa";
                  }
                  } else{
                    if (params.dataIndex === lastItemValue) {
                      return "#8e24aa";
                    }

                  }
                  return "#d6afe0";
                },
              },
              markPoint: {
                data: [
                  {
                    type: "max",
                    name: "Max",
                    symbol: "circle",
                    y: "37%",
                    symbolSize: 20,
                    label: {
                      fontSize: 10,
                    },
                    itemStyle: {
                      color: "#d6afe0",
                    },
                  },
                ],
              },
              data: filteredBarData,
              type: "bar",
            },
          ],
        };

        var storeProtectionBarChartData2 =
          storeProtectionBarChartxSeries.slice(12);
        var stockComplianceBarChartData4 =
          storeProtectionBarChartLoad.yAxisSeries.slice(12);

          if (storeProtectionBarChartxSeries.length <= 12) {
            this.storeProtectionActiveHours = false;
          } else {
            this.storeProtectionActiveHours = true;
          }        

        if (stockComplianceBarChartData4) {
          var filteredBarData1 = stockComplianceBarChartData4.filter(function (
            value: any
          ) {
            return value !== -1;
          });
          var maxIndex1 = stockComplianceBarChartData4.indexOf(
            Math.max(...stockComplianceBarChartData4)
          );
          this.storeProtectionBarChart1 = {
            xAxis: {
              type: "category",
              data: storeProtectionBarChartData2.slice(
                0,
                stockComplianceBarChartData4.length
              ),
              axisLabel: {
                interval: 0,
                rotate: 30,
                fontSize: 8,
                fontWeight: "bold",
              },
            },
            yAxis: {
              show: false,
            },

            height: "40",
            width: "90%",
            series: [
              {
                label: {
                  show: true,
                  position: "top",
                  // formatter: function (params) {
                  //   if (params?.value) {
                  //     return params.value.toString();
                  //   } else {
                  //     return "";
                  //   }
                  // },
                },
                barWidth: 8,
                itemStyle: {
                  borderRadius: [50, 50, 0, 0],
                  color: function (params: any) {
                    if(stockComplianceBarChartData4){
                    if (params.dataIndex === lastItemValue1) {
                      return "#8e24aa";
                    }
                    } else{
                      if (params.dataIndex === lastItemValue) {
                        return "#8e24aa";
                      }
                    }
                    return "#d6afe0";
                  },
                },
                markPoint: {
                  data: [
                    {
                      type: "max",
                      name: "Max",
                      symbol: "circle",
                      y: "37%",
                      symbolSize: 20,
                      label: {
                        fontSize: 10,
                      },
                      itemStyle: {
                        color: "#d6afe0",
                      },
                    },
                  ],
                },
                data: filteredBarData1,
                type: "bar",
              },
            ],
          };
        }
        this.storeProtectionPieChart = {
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: ["80%", "95%"],
              width: 260,
              height: 150,
              startAngle: 180,
              label: {
                show: false,
              },
              data: [
                {
                  value: this.storeProtectionChartSavedValue,
                  name: this.storeProtectionChartSaved,
                  itemStyle: {
                    color: "#9e20a2",
                  },
                },
                {
                  value: this.storeProtectionChartAtRiskValue,
                  name: this.storeProtectionChartAtRisk,
                  itemStyle: {
                    color: "#dd4761",
                  },
                },
                {
                  value:
                    this.storeProtectionChartSavedValue +
                    this.storeProtectionChartAtRiskValue,
                  itemStyle: {
                    color: "none",
                  },
                  label: {
                    show: false,
                  },
                },
              ],
            },
          ],
        };
      });
  }
  metadata = {};

  // Customer Assistance API Integrated Data
  // uncomment below code after demo

  // getAssistanceChartApi() {
  //   this.storeInsightService
  //     .getWidgetData(this.selectedStore, "customer_assistance")
  //     .subscribe((res: any) => {
  //       this.storeAssistanceChartData = res.data;
  //       const barChartLoad = this.storeAssistanceChartData?.charts[0];
  //       var filteredBarData = barChartLoad.yAxisSeries.filter(function (
  //         value: any
  //       ) {
  //         return value !== -1;
  //       });

  //       var maxIndex = barChartLoad.yAxisSeries.indexOf(
  //         Math.max(...barChartLoad.yAxisSeries)
  //       );
  //       this.storeAssistanceBarChart = {
  //         xAxis: {
  //           type: "category",
  //           data: barChartLoad.xAxisSeries,
  //           axisLabel: {
  //             interval: 0,
  //             rotate: 30,
  //             fontSize: 8,
  //             fontWeight: "bold",
  //           },
  //         },
  //         yAxis: {
  //           show: false,
  //         },

  //         height: "40",
  //         width: "90%",
  //         series: [
  //           {
  //             label: {
  //               show: true,
  //               position: "top",
  //               formatter: function (params) {
  //                 if (params.value !== 0) {
  //                   return params.value.toString();
  //                 } else {
  //                   return "";
  //                 }
  //               },
  //             },
  //             barWidth: 8,
  //             itemStyle: {
  //               borderRadius: [50, 50, 0, 0],
  //               color: function (params) {
  //                 if (params?.dataIndex === maxIndex) {
  //                   return "#8e24aa";
  //                 }
  //                 return "#d6afe0";
  //               },
  //             },
  //             markPoint: {
  //               data: [
  //                 {
  //                   type: "max",
  //                   name: "Max",
  //                   symbol: "circle",
  //                   y: "35%",
  //                   symbolSize: 20,
  //                   label: {
  //                     fontSize: 10,
  //                   },
  //                   itemStyle: {
  //                     color: "#8e24aa",
  //                   },
  //                 },
  //               ],
  //             },
  //             data: filteredBarData,
  //             type: "bar",
  //           },
  //         ],
  //       };
  //     });
  // }
  // uncomment above code after demo

  // Remove below code after demo
  getAssistanceChartApi() {
    this.storeAssistanceChartData = {
      title: "Customer_Assistance",
      alert_sent: 121,
      alerts_complete: 5,
      resolution_time: 45,
      time_customer: null,
      dwell: 125,
      top: ["Entrance", "Exit", "Shelf"],
      bottom: ["Monitors", "Window"],
      charts: [
        {
          charttype: "bar",
          xAxisSeries: [
            "6AM",
            "7AM",
            "8AM",
            "9AM",
            "10AM",
            "11AM",
            "12PM",
            "1PM",
            "2PM",
            "3PM",
            "4PM",
            "5PM",
            "6PM",
            "7PM",
            "8PM",
            "9PM",
            "10PM",
            "11PM",
            "12PM",
            "1AM",
            "2AM",
            "3AM",
            "4AM",
            "5AM",
          ],
          yAxisSeries: [0, 0, 54, 22, 0, 3, 0, 0, 0, 0, 0, 0, 13,9,9],
        },
      ],
    };

    const barChartLoad = this.storeAssistanceChartData?.charts[0];
    const barChartLoady = this.storeAssistanceChartData?.charts[0]?.yAxisSeries;

    var barChartLoad1 = barChartLoad.xAxisSeries.slice(0, 12);
    var barChartLoadValues1 = barChartLoad.yAxisSeries.slice(0, 12);    

    if (barChartLoad.xAxisSeries.length <= 12) {
      this.moreActveHours = false;
    } else {
      this.moreActveHours = true;
    }

    if (barChartLoad.yAxisSeries.length > 12) {
      var barChartLoad2 = barChartLoad.xAxisSeries.slice(12);
      var barChartLoadValues2 = barChartLoad.yAxisSeries.slice(12);
    }

    var filteredBarData = barChartLoadValues1?.filter(function (value: any) {
      return value !== -1;
    });
    var filteredBarData1 = barChartLoadValues2?.filter(function (value: any) {
      return value !== -1;
    });

    var lastItemValue = barChartLoady.length - 1;
    var lastItemValue1 = barChartLoadValues1.length - 1;

    var maxIndex = barChartLoad.yAxisSeries.indexOf(
      Math.max(...barChartLoad.yAxisSeries)
    );

    var itemStyleColor = "#8e24aa";
    if (barChartLoadValues2) {
      itemStyleColor = "#d6afe0";
    }
    this.storeAssistanceBarChart = {
      xAxis: {
        type: "category",
        data: barChartLoad1.slice(0, barChartLoadValues1.length),

        axisLabel: {
          interval: 0,
          rotate: 30,
          fontSize: 8,
          fontWeight: "bold",
          show: barChartLoad1.length > 0,
        },
      },
      boundaryGap: true,
      yAxis: {
        show: false,
      },

      height: "40",
      width: "90%",
      series: [
        {
          label: {
            show: true,
            position: "top",
            formatter: function (params) {
              if (params?.value) {
                return params.value.toString();
              } else {
                return "";
              }
            },
          },
          barWidth: 8,
          itemStyle: {
            borderRadius: [50, 50, 0, 0],
            color: function (params) {
              if (barChartLoadValues2) {
                if (params.dataIndex === lastItemValue1) {
                  return "#8e24aa";
                }
              } else {
                if (params.dataIndex === lastItemValue) {
                  return "#8e24aa";
                }
              }
              return "#d6afe0";
            },
          },
          markPoint: {
            data: [
              {
                type: "max",
                name: "Max",
                symbol: "circle",
                y: "35%",
                symbolSize: 20,
                label: {
                  fontSize: 10,
                },
                itemStyle: {
                  color: itemStyleColor,
                },
              },
            ],
          },
          data: filteredBarData,
          type: "bar",
        },
      ],
    };
    var lastItemValue2 = barChartLoadValues2.length - 1;
    this.storeAssistanceBarChart1 = {
      xAxis: {
        type: "category",
        data: barChartLoad2.slice(0, barChartLoadValues2.length),

        axisLabel: {
          interval: 0,
          rotate: 30,
          fontSize: 8,
          fontWeight: "bold",
          show: barChartLoad2.length > 0,
        },
      },
      yAxis: {
        show: false,
      },

      height: "40",
      width: "90%",
      series: [
        {
          label: {
            show: true,
            position: "top",
            formatter: function (params) {
              if (params?.value) {
                return params.value.toString();
              } else {
                return "";
              }
            },
          },
          barWidth: 8,
          itemStyle: {
            borderRadius: [50, 50, 0, 0],
            color: function (params) {
              if (params?.dataIndex === lastItemValue2) {
                return "#8e24aa";
              }
              return "#d6afe0";
            },
          },
          markPoint: {
            data: [
              {
                type: "max",
                name: "Max",
                symbol: "circle",
                y: "35%",
                symbolSize: 20,
                label: {
                  fontSize: 10,
                },
                itemStyle: {
                  color: "#8e24aa",
                },
              },
            ],
          },
          data: filteredBarData1,
          type: "bar",
        },
      ],
    };
  }
  // Remove above code after demo

  // Customer Reception API Integrated Data
  getCustomerReceptionApi() {
    this.storeInsightService
      .getWidgetData(this.selectedStore, "customer_reception")
      .subscribe((res: any) => {
        this.storeReceptionChartData = res.data;
        var storeReceptPieChartLoad = res.data.cases;
        var storeReceptLineChartLoad = res.data.charts[0];
        this.storeReceptLineChartTitles = res.data.charts[0].yAxisSeries;

        const values = [
          storeReceptPieChartLoad[0]?.value,
          storeReceptPieChartLoad[1]?.value,
          storeReceptPieChartLoad[2]?.value,
        ];

        const total = values.reduce((sum, value) => sum + value, 0);
        const percentages = values.map((value) => (value / total) * 100);
        const roundedPercentages = percentages.map((percentage) =>
          parseFloat(percentage.toFixed(1))
        );

        this.storeReceptionPieChart = {
          series: [
            {
              name: "Today's Alerts",
              type: "pie",
              radius: ["35%", "90%"],
              avoidLabelOverlap: false,
              width: 100,
              height: 100,
              label: {
                show: true,
                position: "inner",
                color: "#fff",
                fontWeight: "bold",
              },
              data: [
                {
                  value: storeReceptPieChartLoad[0]?.value,
                  name: roundedPercentages[0] + "%",
                  label: {
                    fontSize: "8px",
                    formatter: (params: any) => {
                      if (
                        params.value !== 0 ||
                        params.value !== null ||
                        params.value !== undefined
                      ) {
                        return params.name;
                      }
                      return "";
                    },
                  },
                  itemStyle: {
                    color: "#cd128a",
                  },
                },
                {
                  value: storeReceptPieChartLoad[1]?.value,
                  name: roundedPercentages[1] + "%",
                  label: {
                    fontSize: "8px",
                    formatter: (params: any) => {
                      if (
                        params.value !== 0 ||
                        params.value !== null ||
                        params.value !== undefined
                      ) {
                        return params.name;
                      }
                      return "";
                    },
                  },
                  itemStyle: {
                    color: "#ed8034",
                  },
                },
                {
                  value: storeReceptPieChartLoad[2]?.value,
                  name: roundedPercentages[2] + "%",
                  label: {
                    fontSize: "8px",
                    formatter: (params: any) => {
                      if (
                        params.value !== 0 ||
                        params.value !== null ||
                        params.value !== undefined
                      ) {
                        return params.name;
                      }
                      return "";
                    },
                  },
                  itemStyle: {
                    color: "#8d23a9",
                  },
                },
              ],
            },
          ],
        };

        this.storeReceptionLineChart = {
          xAxis: {
            type: "category",
            data: storeReceptLineChartLoad.xAxisSeries,
            axisLabel: {
              interval: 0,
              rotate: 30,
              fontSize: 8,
              fontWeight: "bold",
            },
          },
          yAxis: {
            type: "value",
            show: false,
          },
          width: "90%",
          height: 90,
          color: "#dd4761",
          series: [
            {
              data: storeReceptLineChartLoad.yAxisSeries[0].data,
              type: "line",
              lineStyle: {
                color: "#9e20a2",
                width: 2,
                type: "dashed",
              },
              label: {
                show: true,
              },
              itemStyle: {
                color: "#9e20a2",
              },
            },
            {
              data: storeReceptLineChartLoad.yAxisSeries[1]?.data,
              type: "line",
              lineStyle: {
                color: "#dd4761",
                width: 2,
                type: "solid",
              },
              label: {
                show: true,
              },
              itemStyle: {
                color: "#dd4761",
              },
            },
            {
              data: storeReceptLineChartLoad.yAxisSeries[2]?.data,
              type: "line",
              lineStyle: {
                color: "#e86226",
                width: 2,
                type: "dotted",
              },
              label: {
                show: true,
              },
              itemStyle: {
                color: "#e86226",
              },
            },
          ],
        };
      });
  }

  // Self Checkout API Integrated Data
  getSelfCheckoutApi() {
    this.storeInsightService
      .getSelfCheckoutData(this.selectedStore)
      .subscribe((res: any) => {
        this.storeSelfCheckoutChartData = res.data;
        const lineChartLoad = this.storeSelfCheckoutChartData?.charts[0];
        const pieChartLoad = this.storeSelfCheckoutChartData?.charts[1];

        this.storeSelfCheckoutChartDataLables = lineChartLoad?.yAxisSeries;

        this.storeSelfCheckoutSaved = pieChartLoad?.xAxisSeries[0];
        this.storeSelfCheckoutAtRisk = pieChartLoad?.xAxisSeries[1];
        this.storeSelfCheckoutSavedValue = pieChartLoad?.yAxisSeries[0];
        this.storeSelfCheckoutAtRiskValue = pieChartLoad?.yAxisSeries[1];

        this.storeSelfCheckoutPieChart = {
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: ["80%", "95%"],
              width: 260,
              height: 150,
              startAngle: 180,
              label: {
                show: false,
              },
              data: [
                {
                  value: this.storeProtectionChartSavedValue,
                  name: this.storeProtectionChartSaved,
                  itemStyle: {
                    color: "#9e20a2",
                  },
                },
                {
                  value: this.storeProtectionChartAtRiskValue,
                  name: this.storeProtectionChartAtRisk,
                  itemStyle: {
                    color: "#dd4761",
                  },
                },
                {
                  value:
                    this.storeProtectionChartSavedValue +
                    this.storeProtectionChartAtRiskValue,
                  itemStyle: {
                    color: "none",
                  },
                  label: {
                    show: false,
                  },
                },
              ],
            },
          ],
        };

        this.storeSelfCheckoutLineChart = {
          xAxis: {
            type: "category",
            // data: lineChartLoad.xAxisSeries,
            data: ["D-7", "D-6", "D-5", "D-4", "D-3", "D-2", "D-1", "TODAY"],
            axisLabel: {
              interval: 0,
              fontSize: 8,
              fontWeight: "bold",
              formatter: function (value: any) {
                return value.charAt(0).toUpperCase() + value.slice(1);
              },
            },
          },
          yAxis: {
            type: "value",
            show: false,
          },
          width: "90%",
          height: 100,
          color: "#dd4761",
          series: [
            {
              data: lineChartLoad?.yAxisSeries[0]?.data,
              type: "line",
              name: lineChartLoad?.yAxisSeries[0]?.name,
              lineStyle: {
                color: "#9e20a2",
                width: 2,
                type: "dashed",
              },
              label: {
                show: true,
              },
              itemStyle: {
                color: "#9e20a2",
              },
            },
            {
              data: lineChartLoad?.yAxisSeries[1]?.data,
              type: "line",
              name: lineChartLoad?.yAxisSeries[1]?.name,
              lineStyle: {
                color: "#dd4761",
                width: 2,
                type: "solid",
              },
              label: {
                show: true,
              },
              itemStyle: {
                color: "#dd4761",
              },
            },
          ],
        };
      });
  }

  // Loitering API Integrated Data
  getLoiteringChartApi() {
    this.storeInsightService
      .getWidgetData(this.selectedStore, "loitering")
      .subscribe((res: any) => {
        this.storeLoiteringChartData = res.data;
        const barChartLoad = res.data?.charts[0];
        var loiteringBarChartLoad = res.data.charts[0];
        const loiteringBarChartxSeries = loiteringBarChartLoad.xAxisSeries;
        // const loiteringBarChartxSeries = [
        //   "6AM",
        //   "7AM",
        //   "8AM",
        //   "9AM",
        //   "10AM",
        //   "11AM",
        //   "12PM",
        //   "1PM",
        //   "2PM",
        //   "3PM",
        //   "4PM",
        //   "5PM",
        //   "6PM",
        //   "7PM",
        //   "8PM",
        //   "9PM",
        //   "10PM",
        //   "11PM",
        //   "12AM",
        //   "1AM",
        //   "2AM",
        //   "3AM",
        //   "4AM",
        //   "5AM",
        // ];
        var loiteringBarChartData1 = loiteringBarChartxSeries.slice(0, 12);

        var loiteringBarChartData3 = loiteringBarChartLoad.yAxisSeries.slice(
          0,
          12
        );

        var filteredBarData = loiteringBarChartData3.filter(function (
          value: any
        ) {
          return value !== -1;
        });

        var maxIndex = loiteringBarChartData3.indexOf(
          Math.max(...loiteringBarChartData3)
        );

        var lastItemValue = loiteringBarChartLoad.length - 1;
        var lastItemValue1 = loiteringBarChartData1.length - 1;

        var storeLoiteringPieChartLoad = this.storeLoiteringChartData?.cases;

        const values = [
          storeLoiteringPieChartLoad[0]?.value,
          storeLoiteringPieChartLoad[1]?.value,
          storeLoiteringPieChartLoad[2]?.value,
        ];

        const total = values.reduce((sum, value) => sum + value, 0);
        const percentages = values.map((value) => (value / total) * 100);
        const roundedPercentages = percentages.map((percentage) =>
          parseFloat(percentage.toFixed(1))
        );

        this.storeLoiteringPieChart = {
          series: [
            {
              name: "Today's Alerts",
              type: "pie",
              radius: ["35%", "90%"],
              avoidLabelOverlap: false,
              width: 100,
              height: 100,
              label: {
                show: true,
                position: "inner",
                color: "#fff",
                fontWeight: "bold",
              },
              data: [
                {
                  value: storeLoiteringPieChartLoad[0]?.value,
                  name: roundedPercentages[0] + "%",
                  label: {
                    fontSize: "8px",
                    formatter: (params: any) => {
                      if (
                        params.value !== 0 ||
                        params.value !== null ||
                        params.value !== undefined
                      ) {
                        return params.name;
                      }
                      return "";
                    },
                  },
                  itemStyle: {
                    color: "#cd128a",
                  },
                },
                {
                  value: storeLoiteringPieChartLoad[1]?.value,
                  name: roundedPercentages[1] + "%",
                  label: {
                    fontSize: "8px",
                    formatter: (params: any) => {
                      if (
                        params.value !== 0 ||
                        params.value !== null ||
                        params.value !== undefined
                      ) {
                        return params.name;
                      }
                      return "";
                    },
                  },
                  itemStyle: {
                    color: "#ed8034",
                  },
                },
                {
                  value: storeLoiteringPieChartLoad[2]?.value,
                  name: roundedPercentages[2] + "%",
                  label: {
                    fontSize: "8px",
                    formatter: (params: any) => {
                      if (
                        params.value !== 0 ||
                        params.value !== null ||
                        params.value !== undefined
                      ) {
                        return params.name;
                      }
                      return "";
                    },
                  },
                  itemStyle: {
                    color: "#8d23a9",
                  },
                },
              ],
            },
          ],
        };

        this.storeLoiteringBarChart = {
          xAxis: {
            type: "category",
            data: loiteringBarChartData1.slice(
              0,
              loiteringBarChartData3.length
            ),
            axisLabel: {
              interval: 0,
              rotate: 30,
              fontSize: 8,
              fontWeight: "bold",
            },
          },
          yAxis: {
            show: false,
          },

          height: "40",
          width: "90%",
          series: [
            {
              label: {
                show: true,
                position: "top",
                // formatter: function (params) {
                //   if (params?.value) {
                //     return params.value.toString();
                //   } else {
                //     return "";
                //   }
                // },
              },
              barWidth: 8,
              itemStyle: {
                borderRadius: [50, 50, 0, 0],
                color: function (params: any) {
                  if (loiteringBarChartData4){
                  if (params.dataIndex === lastItemValue1) {
                    return "#8e24aa";
                  }
                }else{
                  if (params.dataIndex === lastItemValue) {
                    return "#8e24aa";
                  }
                }
                  return "#d6afe0";
                },
              },
              markPoint: {
                data: [
                  {
                    type: "max",
                    name: "Max",
                    symbol: "circle",
                    y: "35%",
                    symbolSize: 20,
                    label: {
                      fontSize: 10,
                    },
                    itemStyle: {
                      color: "#d6afe0",
                    },
                  },
                ],
              },
              data: filteredBarData,
              type: "bar",
            },
          ],
        };

        var loiteringBarChartData2 = loiteringBarChartxSeries.slice(12);
        var loiteringBarChartData4 =
          loiteringBarChartLoad.yAxisSeries.slice(12);        

        if (loiteringBarChartxSeries.length <= 12) {
          this.storeLoiteringActiveHours = false;
        } else {
          this.storeLoiteringActiveHours = true;
        }
          
        if (loiteringBarChartData4.length) {
          var filteredBarData1 = loiteringBarChartData4.filter(function (
            value: any
          ) {
            return value !== -1;
          });
          var maxIndex1 = loiteringBarChartData4.indexOf(
            Math.max(...loiteringBarChartData3)
          );
          this.storeLoiteringBarChart1 = {
            xAxis: {
              type: "category",

              data: loiteringBarChartData2.slice(
                0,
                loiteringBarChartData4.length
              ),
              axisLabel: {
                interval: 0,
                rotate: 30,
                fontSize: 8,
                fontWeight: "bold",
              },
            },
            yAxis: {
              show: false,
            },

            height: "40",
            width: "90%",
            series: [
              {
                label: {
                  show: true,
                  position: "top",
                  // formatter: function (params) {
                  //   if (params?.value) {
                  //     return params.value.toString();
                  //   } else {
                  //     return "";
                  //   }
                  // },
                },
                barWidth: 8,
                itemStyle: {
                  borderRadius: [50, 50, 0, 0],
                  color: function (params: any) {
                    if (loiteringBarChartData4){
                    if (params.dataIndex === lastItemValue1) {
                      return "#8e24aa";
                    }
                  }else{
                    if (params.dataIndex === lastItemValue) {
                      return "#8e24aa";
                    }
                  }
                    return "#d6afe0";
                  },
                },
                markPoint: {
                  data: [
                    {
                      type: "max",
                      name: "Max",
                      symbol: "circle",
                      y: "35%",
                      symbolSize: 20,
                      label: {
                        fontSize: 10,
                      },
                      itemStyle: {
                        color: "#d6afe0",
                      },
                    },
                  ],
                },
                data: filteredBarData1,
                type: "bar",
              },
            ],
          };
        }
      });
  }

  getCameraDataForHeatMap(storeId: any, useCaseIdentifier: any) {
    this.storeInsightService
      .getCameraDataForHeatMap(storeId, useCaseIdentifier)
      .subscribe((data: any) => {
        this.cameraData = data?.data?.length > 0 ? data?.data : [];
        this.cameraData = this.cameraData?.filter((obj:any) => obj?.cam_image?.url); /// need to remove this when new api is integrated
        this.selectedCamera =
          this.cameraData?.length > 0 && this.cameraData[0]?.camUID
            ? this.cameraData[0]?.camUID
            : "";
        this.heatMapCameraImage =
          this.cameraData?.length > 0 && this.cameraData[0]?.cam_image?.url
            ? this.cameraData[0]?.cam_image?.url
            : "";

        this.storeName =
          this.cameraData?.length > 0 && this.cameraData[0]?.storeName
            ? this.cameraData[0]?.storeName
            : "";
        if (this.selectedCamera) {
          this.getHeatMapData(this.selectedCamera, false);
        }
      });
  }

  /// get the heatmap data
  getHeatMapData(cameraId: any, fromPopup: boolean) {
    this.loading = true;
    const payload = { camera_id: cameraId };
    const today = false; // need to make it false and pass date range while date filter is integrated
    this.storeInsightService.getHeatMapsData(payload, today).subscribe({
      next: (resp: any) => {
        this.heatMapDataSet =
          resp?.data?.segments?.length > 0 ? resp.data.segments : [];
        this.colorArrayLegends =
          resp?.data?.colors?.length > 0 ? resp.data.colors : [];
        if (fromPopup) {
          this.heatMapModalRef.componentInstance.heatMapDataSet =
            this.heatMapDataSet;
          this.heatMapModalRef.componentInstance.colorArrayLegends =
            this.colorArrayLegends;
        }
        this.loading = false;
      },
      error: (error: any) => {
        this.heatMapDataSet = [];
        this.colorArrayLegends = [];
        if (fromPopup) {
          this.heatMapModalRef.componentInstance.heatMapDataSet =
            this.heatMapDataSet;
          this.heatMapModalRef.componentInstance.colorArrayLegends =
            this.colorArrayLegends;
        }
        this.loading = false;
      },
    });
  }

  /// popup to open heat map larger view
  openHeatMapPopup() {
    this.heatMapModalRef = this.modalService.open(
      HeatMapContainerPopupComponent,
      {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "heat-map-modal",
        backdrop: "static",
        keyboard: false,
      }
    );
    this.heatMapModalRef.componentInstance.heatMapDataSet = this.heatMapDataSet;
    this.heatMapModalRef.componentInstance.colorArrayLegends =
      this.colorArrayLegends;
    this.heatMapModalRef.componentInstance.cameraData = this.cameraData;
    this.heatMapModalRef.componentInstance.selectedCamera = this.selectedCamera;
    this.heatMapModalRef.componentInstance.heatMapCameraImage =
      this.heatMapCameraImage;
    this.heatMapModalRef.componentInstance.storeName = this.storeName;
    this.heatMapModalRef.componentInstance.currentTime = this.currentTime;
    this.heatMapModalRef.componentInstance.selectedCameraValuePopup.subscribe(
      (event: any) => {
        this.filterHeatMapDataByCamera(event, true);
      }
    );
  }

  selectedCameraValue(event: any) {
    this.filterHeatMapDataByCamera(event, false);
  }

  filterHeatMapDataByCamera(cameraId: any, fromPopup: boolean) {
    if (cameraId) {
      const cameraObj = this.cameraData.find(
        (obj: any) => obj["camUID"] === cameraId
      );
      this.selectedCamera =
        cameraObj && cameraObj?.camUID ? cameraObj?.camUID : "";
      this.heatMapCameraImage =
        cameraObj && cameraObj?.cam_image?.url ? cameraObj?.cam_image?.url : "";

      this.storeName =
        cameraObj && cameraObj?.storeName ? cameraObj?.storeName : "";
      this.currentTime = new Date();
      if (fromPopup) {
        this.heatMapModalRef.componentInstance.selectedCamera =
          this.selectedCamera;
        this.heatMapModalRef.componentInstance.heatMapCameraImage =
          this.heatMapCameraImage;

        this.heatMapModalRef.componentInstance.storeName = this.storeName;
        this.heatMapModalRef.componentInstance.currentTime = this.currentTime;
      }
      this.getHeatMapData(cameraId, fromPopup);
    }
  }

  getPathwayData() {
    this.storeInsightService.getPathwayDataFromJson().subscribe({
      next: (resp: any) => {
        this.pathwayDataSet = resp;
      },
      error: (error: any) => {
        console.log("Error Occured", error);
      },
    });
  }

  openPathwayPopup() {
    this.pathwayModalRef = this.modalService.open(
      PathwayContainerPopupComponent,
      {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "heat-map-modal",
        backdrop: "static",
        keyboard: false,
      }
    );
    this.pathwayModalRef.componentInstance.dashboardWidget =
      this.dashboardWidget;
  }

}
