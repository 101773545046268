import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  QueryList,
  Renderer2,
  OnDestroy,
} from "@angular/core";
import { LayoutService } from "src/app/services/layout.service";
import { ApiService } from "src/app/services/api.service";
import { DatePipe } from "@angular/common";
import { DataService } from "../../../services/search.service";
import {
  ModalDismissReasons,
  NgbModal,
  NgbActiveModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { CreateCaseComponent } from "./create-case/create-case.component";
import Swal from "sweetalert2";
import {
  catchError,
  map
} from "rxjs/operators";
import { throwError, Observable } from "rxjs";
import { VideoService } from "../../../services/video.service";
import { Router } from '@angular/router';
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import jwt_decode from "jwt-decode";
import { AuthorizationService } from "src/app/services/authorization.service";

@Component({
  selector: "app-my-tasks",
  templateUrl: "./my-tasks.component.html",
  styleUrls: ["./my-tasks.component.scss"],
  providers: [DatePipe],
})
export class MyTasksComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("toast", { static: true }) toastElement: ElementRef;
  @ViewChild("feedbackComments", { static: true }) feedbackComments: ElementRef;
  @ViewChild("scrollContainer") scrollContainersRef: QueryList<ElementRef>;
  @ViewChild("inputRef", { static: false })
  inputElement: ElementRef<HTMLInputElement>;

  @ViewChild("primaryTab1", { static: true }) primaryTab1: ElementRef;
  @ViewChild("primaryTab2", { static: true }) primaryTab2: ElementRef;
  @ViewChild("primaryTab3", { static: true }) primaryTab3: ElementRef;

  @ViewChild("secondaryTab1", { static: true }) secondaryTab1: ElementRef;
  @ViewChild("secondaryTab2", { static: true }) secondaryTab2: ElementRef;
  @ViewChild("secondaryTab3", { static: true }) secondaryTab3: ElementRef;
  @ViewChild("secondaryTab4", { static: true }) secondaryTab4: ElementRef;
  @ViewChild("secondaryTab5", { static: true }) secondaryTab5: ElementRef;

  @ViewChild("secondaryTab6", { static: true }) secondaryTab6: ElementRef;
  @ViewChild("secondaryTab7", { static: true }) secondaryTab7: ElementRef;
  @ViewChild("secondaryTab8", { static: true }) secondaryTab8: ElementRef;
  @ViewChild("secondaryTab9", { static: true }) secondaryTab9: ElementRef;
  @ViewChild("secondaryTab10", { static: true }) secondaryTab10: ElementRef;
  @ViewChild("secondaryTab11", { static: true }) secondaryTab11: ElementRef;
  @ViewChild("secondaryTab12", { static: true }) secondaryTab12: ElementRef;
  @ViewChild("secondaryTab13", { static: true }) secondaryTab13: ElementRef;
  @ViewChild("secondaryTab14", { static: true }) secondaryTab14: ElementRef;
  @ViewChild("secondaryTab15", { static: true }) secondaryTab15: ElementRef;

  // Primary and secondary tab elements grouped into arrays
  primaryTabs: ElementRef[] = [];
  secondaryTabs: ElementRef[] = [];

  // Boolean flags indicating various states
  public isFetchingData = false;
  public isSubmitted = false;
  public isActive = false;
  public isDisplay = false;
  public isActionActive = true;
  public selectedButton = false;
  public scrollingDown = true;
  public isDwellTags = false;
  public feedbackAllTags = false;
  provideReason: boolean = false;
  windowScrolling: boolean = false;
  taskread: boolean = false;
   initialTask : any;  
  // Variables related to task details and counters
  public debounceTimeout: any;
  public selectedTask: any;
  public selectedStatus: any = "New";
  public selectedUsecaseId: any;
  public taskCounterArray: any;
  public taskActionFeedback: any;
  public selectedTaskId: any;
  public selectedActionId: any;
  public taskActions: any;
  public selectedReason: any = "";
  public selectedTag: any = "";
  public originalTaskActionFeedback: string = "";
  public originalSelectedReason: string = "";
  public originalSelectedTag: any;

  currentVideoIndex = 0; // Initially, the first video is active

  // Arrays to store different kinds of data
  taskList: any[] = [];
  taskCounter: any[] = [];
  defaultTaskData: any[] = [];
  feedbackTags: any[] = [];
  feedbackActionTags: any[] = [];
  feedbackList: any;
  dwellTags: any[] = [];
  filteredData: any[] = [];
  severityList: any = [
    { id: "Severity 1", type: "Severity 1" },
    { id: "Severity 2", type: "Severity 2" },
    { id: "Severity 3", type: "Severity 3" },
  ];
  selectedSeverity: any = "Severity 1";

  statusTabs = [
    { id: 1, name: "New", label: "New", title: "Current" },
    { id: 3, name: "Completed", label: "Completed", title: "Completed" },
    { id: 2, name: "Blocked", label: "Blocked", title: "Blocked" },
  ];

  selectedStatusId: any;
  duplicateTaskList:any[] = [];

  useCaseTabs = [
    { name: "all", label: "All", useCase: "", icon: "all", disabled: false },
    {
      name: "dumpster_person",
      label: "Dumpster Person",
      useCase: "Dumpster Person",
      icon: "person",
      disabled: false
    },
    {
      name: "driveThru_dw_vehicle",
      label: "DriveThru DW Vehicle",
      useCase: "DriveThru DW Vehicle",
      icon: "vehicle",
      disabled: false
    },
    {
      name: "driveThru_aw_Person",
      label: "DriveThru AW Person",
      useCase: "DriveThru AW Person",
      icon: "person",
      disabled: true
    },
    {
      name: "dumpster_vehicle",
      label: "Dumpster Vehicle",
      useCase: "Dumpster Vehicle",
      icon: "vehicle",
      disabled: true
    },
    {
      name: "driveThru_aw_vehicle",
      label: "DriveThru AW Vehicle",
      useCase: "DriveThru AW Vehicle",
      icon: "vehicle",
      disabled: true
    },

    {
      name: "grill_remove_marker",
      label: "Grill Remove Marker",
      useCase: "Grill_Remove_Marker",
      icon: "in-store",
      disabled: true
    },
    {
      name: "grill_place_marker",
      label: "Grill Place Marker",
      useCase: "grill_place_marker",
      icon: "in-store",
      disabled: true
    },
    {
      name: "grill_remove_food",
      label: "Grill Remove Food",
      useCase: "Grill_Remove_Food",
      icon: "in-store",
      disabled: true
    },
    
    {
      name: "ca_beer_cave",
      label: "CA Beercave",
      useCase: "CA_BeerCave",
      icon: "in-store",
      disabled: true
    },
    {
      name: "ca_front_door",
      label: "CA Front Door",
      useCase: "CA_Front_Door",
      icon: "in-store",
      disabled: true
    },
    {
      name: "ca_gas_station",
      label: "CA Gas Station",
      useCase: "CA_Gas_Station",
      icon: "in-store",
      disabled: true
    },
  ];

  loggedUser: any;
  // Variables for pagination and selection
  baseIndex = 0;
  baseFirstIndex = 1;
  clickedRowIndex = 0;
  pageSize = 0;
  currentPage = 1;
  selectedStatusTab = 1;
  selectedUseCaseTab = 1;
  defaultStatusTab = 0;
  defaultUseCaseTab = 0;
  minPageLength = 20;
  totalTasksList: any;
  totalIncidentCount:number;

  totalItems: number = 0;
  searchQuery = "";
  selectedUseCase = "";

  taskVideoUrl: any;
  selectedMonitorId?: string;
  displayStartTime: any;
  taskCreatedTime: any;
  taskVmsPlayTime: any;
  closeResult: string; 
  autoRefereshTasksInterval: any;
  mainTaskId: any="";
  decodedToken: any;

  constructor(
    private layoutService: LayoutService,
    private apiService: ApiService,
    private dataService: DataService,
    public modalService: NgbModal,
    public modal: NgbActiveModal,
    private renderer: Renderer2,
    private videoService: VideoService,
    private router: Router,
    private alertMessagesService: AlertMessagesService,
    private authorizationService: AuthorizationService
  ) {
    this.primaryTabs = [this.primaryTab1, this.primaryTab2, this.primaryTab3];

    this.secondaryTabs = [
      this.secondaryTab1,
      this.secondaryTab2,
      this.secondaryTab3,
      this.secondaryTab4,
      this.secondaryTab5,
      this.secondaryTab6,
      this.secondaryTab7,
      this.secondaryTab8,
      this.secondaryTab9,
      this.secondaryTab10,
      this.secondaryTab11,
      this.secondaryTab12,
      this.secondaryTab13,
      this.secondaryTab14,
      this.secondaryTab15,
    ];
    this.loggedUser = localStorage.getItem("userRole");
  }

  ngOnInit(): void {
    this.getLoggedUser();
  }

  getLoggedUser() {
    const accessToken = localStorage.getItem("auth_token") ?? "default";

    this.decodedToken = jwt_decode(accessToken);
 
    if ( this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.tasks, ['task', '*']) ) {
      const pageTitle = "My Tasks";
      this.layoutService.setPageTitle(pageTitle);
      this.filterSearchData();
      this.autoRefresh();
    } else {
      this.router.navigateByUrl('/unauthorized');
    }
  }

  changeSeverity(selectedTask: any, event: any) {
    this.selectedSeverity = event.target.value;

    this.apiService
      .updateTaskSeverity(selectedTask?.taskId, this.selectedSeverity)
      .subscribe((resp: any) => {
        this.autoRefreshTaskData(
          this.currentPage,
          this.selectedUseCase,
          this.selectedStatusTab,
          this.searchQuery
        );
        if (resp.status === true) {           
          this.alertMessagesService.alertWithSuccess("Severity Upadated Successfully.");
        }
      });
  }

  ngAfterViewInit() {
    this.scrollContainersRef.changes?.subscribe(
      (scrollContainers: QueryList<ElementRef>) => {
        scrollContainers.forEach((scrollContainer: ElementRef) => {
          const containerElement: HTMLElement = scrollContainer.nativeElement;
          if (containerElement) {
            this.renderer.listen(containerElement, "scroll", (event: Event) => {
              this.onScroll(event, containerElement);
            });
          }
        });
      }
    );
  }

  filterSearchData() {
    this.dataService.getSearchValue().subscribe((query: any) => {
      this.searchQuery = query;
      if (this.searchQuery) {
        this.taskList = [];
        this.currentPage = 1;
      }
      if ( this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.tasks, ['task', '*']) ) {
        this.getInitialTasksData(
          this.currentPage,
          this.selectedUseCase,
          this.selectedStatusTab,
          this.searchQuery
        );
        this.getAllTasksData(
          this.currentPage,
          this.selectedUseCase,
          this.selectedStatusTab,
          this.searchQuery
        );
        // this.getAllTaskActions();
      }
    });
  }

  getDuplicate(taskId: any) {    
    //   this.apiService.getDuplicateTasks(taskId).subscribe((resp:any) =>{      
    //   this.duplicateTaskList = resp.data;
    //   this.selectedTask.duplicates_count=this.duplicateTaskList.length;    
    // })
  }

  getInitialTasksData(
    currentPage: number,
    selectedUseCase: string,
    selectedStatusTab: number,
    searchQuery: string
  ) {
    this.apiService
      .getTaskData(currentPage, selectedUseCase, selectedStatusTab, searchQuery)
      .subscribe((resp: any) => {
        this.taskCounter = resp.taskCountByStatus;      
        this.taskList = resp.tasks;
        this.initialTask = this.taskList[0];
        this.selectedTask = this.taskList[0]
        this.totalIncidentCount = this.selectedTask.duplicates_count + 1;
        this.selectedSeverity = this.taskList[0]?.severity;
        this.selectedStatus = this.taskList[0]?.status;
        this.selectedTag = this.taskList[0]?.tag;
        this.selectedTask.read = true;
        this.taskActionFeedback = this.taskList[0]?.feedbackcomments;
        this.selectedReason = this.taskList[0]?.reasontext;
        this.clickedRowIndex = 0;
        this.selectedUsecaseId = this.taskList[0]?.useCaseUID;
        const selectedUseCaseName = this.taskList[0]?.useCaseName;
        this.taskVideoUrl = "";
        this.getDuplicate(this.initialTask.taskId);             

        if (selectedUseCaseName !== "PeopleMovement") {
          this.videoTimeFormatter(selectedUseCaseName, this.selectedTask);
        }
        // this.filterActionItems(this.taskActions);
      });
  }

  makeitaCase(task: any) {
    const modalRef = this.modalService.open(CreateCaseComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-widget-modal make-it-case",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.createdCaseInfo = task;
    modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        localStorage.setItem("casemanagementData", JSON.stringify(task));
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getAllTasksData(
    currentPage: number,
    selectedUseCase: string,
    selectedStatusTab: number,
    searchQuery: string
  ): Observable<any> {
    return this.apiService
      .getTaskData(currentPage, selectedUseCase, selectedStatusTab, searchQuery)
      .pipe(
        map((resp: any) => {
          this.taskCounter = resp.taskCountByStatus;
          const newTasks = resp.tasks.map((task: any) => {
            return task;
          });
          return newTasks;
        })
      );
  }

  getAllTaskActions() {
    this.apiService
      .getTaskActions()
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        })
      )
      .subscribe((resp: any) => {
        this.taskActions = resp.data;
        // this.filterActionItems(this.taskActions);
      });
  }

  // Fetch Task Details
  fetchTaskDetails(item: any, index: number, mainTaskId:any="") {
    this.clickedRowIndex = index;
    this.initialTask = item;
    this.selectedTask = item;
    this.totalIncidentCount = this.selectedTask.duplicates_count + 1;   
    this.selectedUsecaseId = item?.useCaseUID;
    this.selectedTag = item?.tag;
    this.taskActionFeedback = item?.feedbackcomments;
    this.selectedReason = item?.reasontext;
    this.selectedSeverity = item?.severity;
    this.selectedStatus = item?.status;
    const selectedTaskId = item?.taskId;
    const selectedUseCaseName = item?.useCaseName;
    this.taskVideoUrl = "";

    if (this.selectedTask?.read === false) {
      this.apiService
        .changeTaskReadValue(selectedTaskId)
        .subscribe((resp: any) => {
          item.read = true;
          this.autoRefreshTaskData(
            this.currentPage,
            this.selectedUseCase,
            this.selectedStatusTab,
            this.searchQuery
          );
        });
    }
    // if (this.selectedUsecaseId === this.selectedTask?.useCaseUID) {
    //   this.filterActionItems(this.taskActions);
    // }
    if (selectedUseCaseName !== "PeopleMovement") {
      this.videoTimeFormatter(selectedUseCaseName, item);
    }   
    this.getDuplicate(item.taskId);  
  }

  duplicateTaskDetails(index1:number){    
    if (index1 == 0){
      this.selectedTask = this.initialTask;
    }else{   
    var n=this.duplicateTaskList.filter(x => x.index == index1);    
    this.selectedTask=n[0];      
  }
   this.selectedUsecaseId = this.selectedTask?.useCaseUID;
    this.selectedTag = this.selectedTask?.tag;
    this.taskActionFeedback = this.selectedTask?.feedbackcomments;
    this.selectedReason = this.selectedTask?.reasontext;
    this.selectedSeverity = this.selectedTask?.severity;
    this.selectedStatus = this.selectedTask?.status;
    this.taskVideoUrl = "";
  } 

  videoTimeFormatter(selectedUseCaseName: any, item: any) {
    // Format start time
    const dateTimeFrame = item?.createdOn;
    const vmsStartDateObject = new Date(dateTimeFrame);
    if (vmsStartDateObject.getMinutes() % 2 !== 0) {
      vmsStartDateObject.setSeconds(vmsStartDateObject.getSeconds() - 60);
    }
    const vmsStartTimeMinute = vmsStartDateObject
      .toISOString()
      .replace(".000Z", "");
    const vmsStartTime = vmsStartTimeMinute.slice(0, -2) + "00";

    // Format end time
    const vmsEndDateObject = new Date(dateTimeFrame);
    if (vmsEndDateObject.getMinutes() % 2 !== 0) {
      vmsEndDateObject.setSeconds(vmsEndDateObject.getSeconds() - 60);
    }
    const vmsEndtime = new Date(vmsEndDateObject.getTime() + 120 * 1000);
    const vmsEndTimeMinute = vmsEndtime.toISOString().replace(".000Z", "");
    const vmsEndTime = vmsEndTimeMinute.slice(0, -2) + "00";

    this.taskVmsPlayTime = vmsStartTimeMinute;
    this.taskCreatedTime = item?.createdOn.slice(0, -1);

    this.getVideoInfo(selectedUseCaseName, vmsStartTime, vmsEndTime);
  }

  getVideoInfo(
    selectedUseCaseName: string,
    vmsStartTime: any,
    vmsEndDate: any
  ) {
    if (selectedUseCaseName == "Dwell" || selectedUseCaseName == "Violence") {
      this.selectedMonitorId = "6aiXN3oEcL";
    } else if (
      selectedUseCaseName == "OOS" ||
      selectedUseCaseName == "Shrinkage"
    ) {
      this.selectedMonitorId = "avtkFUsstP";
    } else if (selectedUseCaseName == "checkout_shrink") {
      this.selectedMonitorId = "Cnl40hoiDr";
    }

    if (this.selectedMonitorId !== undefined) {
      this.videoService
        .getTaskVideoData(this.selectedMonitorId, vmsStartTime)
        .subscribe((data: any) => {
          const videoItems = data.videos;
          const videoItem = videoItems[videoItems.length - 1];
          if (videoItems.length !== 0) {
            const baseVideoUrl = this.videoService.videoApiUrl;
            const videoUrl = videoItem?.actionUrl;
            this.taskVideoUrl = baseVideoUrl + videoUrl;
          }
        });
    }
  }

  changeTaskStatus(event: any, taskId: any) {
    const status = event.target.value;
    const statusInfo: Record<string, { title: string; message: string }> = {
      "1": { title: "New", message: "Task Status Changed Successfully." },
      "2": { title: "Blocked", message: "Task Blocked Successfully." },
      "3": { title: "Completed", message: "Task Completed Successfully." },
    };

    if (status == "New") {
      this.selectedStatusId = 1;
    } else if (status == "Blocked") {
      this.selectedStatusId = 2;
    } else if (status == "Completed") {
      this.selectedStatusId = 3;
    }

    this.apiService
      .changeTaskStatus(this.selectedStatusId, taskId)
      .subscribe((res: any) => {
        this.taskList = [];
        
        this.getInitialTasksData(
          this.currentPage,
          this.selectedUseCase,
          this.selectedStatusTab,
          this.searchQuery
        );
        const statusData = statusInfo[status];
        this.alertMessagesService.alertWithSuccess("Task updated to " + status + " successfully");
      });
  }

  onTabChange(selectedTabId: number) {
    this.taskList = [];
    this.selectedStatusTab = selectedTabId;
    if (selectedTabId) {
      this.currentPage = this.baseFirstIndex;
      this.getInitialTasksData(
        this.currentPage,
        this.selectedUseCase,
        selectedTabId,
        this.searchQuery
      );
    }
  }

  onSubTabChange(useCaseName: string, selectedTabId: number) {
    this.taskList = [];
    this.selectedUseCase = useCaseName;
    this.selectedUseCaseTab = selectedTabId;
    this.currentPage = this.baseFirstIndex;
    this.apiService
      .getTaskData(
        this.currentPage,
        useCaseName,
        selectedTabId,
        this.searchQuery
      )
      .subscribe((resp: any) => {
        this.updateTaskListAndCounters(resp, selectedTabId);
      });
  }

   updateTaskListAndCounters(resp: any, status: number) {
    if (resp.tasks[0]?.taskId === 0 || resp.tasks.length === 0) {
      this.isDisplay = true;
      this.taskList = [];
    } else {
      this.isDisplay = false;
      this.taskList = resp.tasks;
    }
    this.selectedTask = this.taskList[0];
    this.clickedRowIndex = 0;
    this.taskCounter = resp.taskCountByStatus;
  }

  windowScroll() {
    window.addEventListener("scroll", () => {
      this.windowScrolling = true;
    });
  }

  onScroll(event: Event, scrollContainer: HTMLElement) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      const scrollTop = scrollContainer.scrollTop;
      const scrollHeight = scrollContainer.scrollHeight;
      const clientHeight = scrollContainer.clientHeight;
      const scrollEndOffset = 1;

      const isScrollAtBottom =
        scrollTop + clientHeight >= scrollHeight - scrollEndOffset;
      const isScrollHeightReached = scrollTop + clientHeight === scrollHeight;

      if (scrollTop > this.clickedRowIndex) {
        this.scrollingDown = true;
      } else {
        this.scrollingDown = false;
      }

      if (
        isScrollAtBottom &&
        isScrollHeightReached &&
        !this.isFetchingData &&
        this.hasMoreItems()
      ) {
        this.isFetchingData = true;
        const remainingItems =
          this.taskCounter[0]?.statusCount - this.taskList.length;

        const itemsToLoad = Math.min(remainingItems, this.pageSize);

        if (itemsToLoad < this.minPageLength) {
          this.currentPage++;
        }

        this.getAllTasksData(
          this.currentPage,
          this.selectedUseCase,
          this.selectedStatusTab,
          this.searchQuery
        ).subscribe((newItems: any[]) => {
          // Append new items directly to the existing taskList
          this.taskList = [...this.taskList, ...newItems];

          this.isFetchingData = false;
          this.clickedRowIndex = scrollTop;
        });
      }
      if (scrollTop === 0) {
        const pageNumber = 1;
        this.currentPage = 1;
        this.autoRefreshTaskData(
          pageNumber,
          this.selectedUseCase,
          this.selectedStatusTab,
          this.searchQuery
        );
      }
    }, 100);
  }

  hasMoreItems(): boolean {
    this.totalItems =
      this.taskCounter[0]?.statusCount +
      this.taskCounter[1]?.statusCount +
      this.taskCounter[2]?.statusCount;
    return this.taskList.length < this.totalItems;
  }

  ngOnDestroy() {
    clearInterval(this.autoRefereshTasksInterval);
  }

  // Auto Refresh the Tasks
  autoRefresh(): void {
    this.autoRefereshTasksInterval = setInterval(() => {
      this.autoRefreshTaskData(
        this.currentPage,
        this.selectedUseCase,
        this.selectedStatusTab,
        this.searchQuery
      );
    }, 60000);
  }

  autoRefreshTaskData(
    currentPage: number,
    selectedUseCase: string,
    selectedStatusTab: number,
    searchQuery: string
  ): Promise<any> {
    return new Promise((resolve: any) => {
      this.apiService
        .getTaskData(
          currentPage,
          selectedUseCase,
          selectedStatusTab,
          searchQuery
        )
        .subscribe((resp: any) => {
          const newItemsFromApi = resp.tasks;
          const newItems = newItemsFromApi
            .map((newItem: any) => {
              const existingIndex = this.taskList.findIndex(
                (existingItem) => newItem.taskId === existingItem.taskId
              );
              if (existingIndex !== -1) {
                this.taskList[existingIndex].tag = newItem.tag;
                this.taskList[existingIndex].reasontext = newItem.reasontext;
                this.taskList[existingIndex].feedbackcomments =
                  newItem.feedbackcomments;
                this.taskList[existingIndex].severity = newItem.severity;
              }
              return newItem;
            })
            .filter((newItem: any) => {
              return !this.taskList.some(
                (existingItem) => newItem.taskId === existingItem.taskId
              );
            });

          this.taskList = [...newItems, ...this.taskList];
          this.clickedRowIndex = this.clickedRowIndex + newItems.length;
          // Update other properties as needed
          this.taskCounter = resp.taskCountByStatus;
          resolve(this.taskList);
        });
    });
  }

  // Filter Task Actions based on UsecaseUID
  filterActionItems(taskActionItems: any) {
    this.feedbackTags = taskActionItems?.filter(
      (action: any) => action.useCaseID === this.selectedUsecaseId
    );

    this.feedbackList = taskActionItems?.filter(
      (item: any) => item.useCaseID === this.selectedUsecaseId
    );
    this.selectedActionId = this.feedbackTags?.[0]?.actionId;
  }

  // Give Feedback
  taskTagActions(selectedTask: any, action: any, index: any) {
    this.feedbackActionTags = action.feedbackData;
    this.selectedTaskId = selectedTask.taskId;
    this.selectedActionId = action.actionId;
    this.originalTaskActionFeedback = selectedTask.feedbackcomments;
    this.originalSelectedReason = selectedTask.reasontext;
    this.originalSelectedTag = selectedTask.tag;

    if (action.ispopup === true) {
      if (this.selectedTag !== this.selectedActionId) {
        this.taskActionFeedback = "";
        this.selectedReason = "";
      }
      const modalRef: NgbModalRef = this.modalService.open(
        this.feedbackComments
      );
      modalRef.result.then(
        (result) => {
          if (result === "submit") {
            this.submitFeedback(this.selectedTaskId, modalRef);
            this.selectedActionId = action.actionId;
          }
        },
        (reason) => {}
      );
    } else {
      this.apiService
        .updateTaskAction(this.selectedTaskId, this.selectedActionId)
        .subscribe((resp: any) => {
          if (resp) {
            this.autoRefreshTaskData(
              this.currentPage,
              this.selectedUseCase,
              this.selectedStatusTab,
              this.searchQuery
            );
            this.selectedTag = resp?.actionId;
            //this.selectedTask = this.taskList[0];
          }
        });
    }
  }

  submitFeedback(selectedTaskId: any, modalRef: any) {
    if (this.selectedReason && this.taskActionFeedback) {
      this.provideReason = false;
      this.apiService
        .updateTaskFeedback(
          selectedTaskId,
          this.selectedActionId,
          this.taskActionFeedback,
          this.selectedReason
        )
        .subscribe((resp: any) => {
          if (resp) {
            modalRef.close();
            this.autoRefreshTaskData(
              this.currentPage,
              this.selectedUseCase,
              this.selectedStatusTab,
              this.searchQuery
            );
            this.selectedTag = resp.actionId;
            //this.selectedTask = this.taskList[0];
            // this.taskActionFeedback = "";
            // this.selectedReason = "";
            this.provideReason = false;
          }
        });
    }
  }

  toggleActiveClassOnTabs(tabs: ElementRef[], activeIndex: number) {
    tabs.forEach((tab, index) => {
      if (index === activeIndex) {
        tab.nativeElement.classList.add("active");
      } else {
        tab.nativeElement.classList.remove("active");
      }
    });
  }

  tabSelection() {
    this.toggleActiveClassOnTabs(this.primaryTabs, 0);
    this.toggleActiveClassOnTabs(this.secondaryTabs, -1);
  }

  inputFeedbackReason() {
    if (this.selectedReason && this.taskActionFeedback) {
      this.provideReason = false;
    }
  }

  dismissModal(modal: any) {
    //assigning original values if popup is closed
    this.taskActionFeedback = this.originalTaskActionFeedback;
    this.selectedReason = this.originalSelectedReason;
    this.selectedTag = this.originalSelectedTag;
    modal.close();
  }

  prevVideo() {
    if (this.currentVideoIndex > 0) {
      this.currentVideoIndex--;
    }
  }

  nextVideo() {
    if (this.currentVideoIndex < this.selectedTask?.videoList.length - 1) {
      this.currentVideoIndex++;
    }
  }
}
// function resolve(taskList: any[]) {
//   throw new Error("Function not implemented.");
// }
