import { Component, OnInit,ElementRef, ViewChild, AfterViewInit} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LayoutService } from "src/app/services/layout.service";
import { StoreInsightService } from "src/app/services/store-insight.service";
import { AuthorizationService } from "src/app/services/authorization.service";
import { Router } from "@angular/router";
import jwt_decode from "jwt-decode";

import * as echarts from "echarts/core";
import { TitleComponent, TooltipComponent, VisualMapComponent, GridComponent, GraphicComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { EffectScatterChart } from 'echarts/charts';
// Register the required components
echarts.use([TitleComponent, TooltipComponent, VisualMapComponent, GridComponent, GraphicComponent, CanvasRenderer, EffectScatterChart]);
interface ROI {
  x_min: number;
  y_min: number;
  x_max: number;
  y_max: number;
}

@Component({
  selector: 'app-roi-configurations',
  templateUrl: './roi-configurations.component.html',
  styleUrls: ['./roi-configurations.component.scss']
})
export class RoiConfigurationsComponent implements OnInit, AfterViewInit {
  @ViewChild('peopleCountHeatMapCanvas', { static: true }) peopleCountHeatMapCanvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;
  configurationForm: FormGroup;
  isSubmitted: boolean = false;
  isLoading: boolean = false;

  loggedUser: any;
  decodedToken: any;
  isCountriesAllowed: boolean = false;
  isStatesAllowed: boolean = false;
  isZipCodeAllowed: boolean = false;
  isStoresAllowed: boolean = false;

  selectedCountry = [{countryName: "USA", countryUID:"1"}];
  selectedtState = [{stateName: "New York", stateUID:"1"}];
  selectedZipcode = [{zipCode: "100101", }];
  selectedStores: any = [{storeId: "d1e524a2-4406-43f7-a968-58542834c4c3", storeName: "Store 8"}];
  defaultUsecasesList: any = [
    {usecaseUID: "e9ee169c-9bf1-48c7-ad74-f9c7c01e1d5e", usecaseName: "Dumpster"},
    {usecaseUID: "7d5eae02-2a8b-4c76-8f55-b5f9079123f3", usecaseName: "Drivethru During Work"},
    {usecaseUID: "674567b8-4ba4-4d69-a9aa-2ddf9c99fd11", usecaseName: "Drivethru After Work"},
    {usecaseUID: "2f9e6b3d-8a4c-4b1e-9f5d-7c3a8e5d2b1a", usecaseName: "Customer Indoor Alerts"},
    {usecaseUID: "3a7e9c4d-6f8b-4e2a-9d5c-1b8f3e2d7c6a", usecaseName: "Customer Outdoor Alerts"},
    {usecaseUID: "7e4d5c2b-8f1a-4b9e-a6d3-c5b2e7f1d9a3", usecaseName: "Hot Grill"}
  ];

  countriesList: any;
  statesList: any;
  zipCodesList: any;
  storesList: any;

  defaultCountry: string = "select";
  defaultState: string = "select";
  defaultZip: string = "select";
  defaultStore: string = "select";
  selectedStore: any = "7a9b5b2f-5f68-4f2b-8123-abcdef123456";
  defaultHeatmapImage: any = "../../../../../assets/store_9_layout.png";
  canvasWidth: any = 1200;
  canvasHeight: any = 500;
  imageWidth: number = 1200;
  imageHeight: number = 500;

  selectedROIs: ROI[] = [];
  selectedUsecase: string = "";

  selectedConfigROI: ROI = {
    x_min: 0,
    y_min: 0,
    x_max: 0,
    y_max: 0
  };

  // For ROI drawing state
  private isDrawing: boolean = false;
  private x_min: number = 0;
  private y_min: number = 0;
  private x_max: number = 0;
  private y_max: number = 0;
  private scaleX: number = 1;
  private scaleY: number = 1;

  constructor(
    private layoutService: LayoutService,
    private storeInsightService: StoreInsightService,
    private authorizationService: AuthorizationService,
    private router: Router
  ) {
    this.loggedUser = localStorage.getItem("userRole");
  }

  ngOnInit(): void {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.decodedToken = jwt_decode(accessToken);
    this.isCountriesAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.countries, ['detail', '*']);
    this.isStatesAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.states, ['detail', '*']);
    this.isZipCodeAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.master, ['list', '*']);
    this.isStoresAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.stores, ['get-store-by-zipcode', '*']);
    
    if ( this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.tasks, ['list', '*']) ) {
      const pageTitle = "ROI Configurations";
      this.layoutService.setPageTitle(pageTitle);
    }
    else if(this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.orgs, ['list', '*']) 
      || this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.stores, ['list', '*'])) {
      this.router.navigateByUrl('/unauthorized');
    }
  }

  ngAfterViewInit(): void {
    this.initializeCanvas();
    this.registerCanvasEvents();
  }

  getCountries() {
    this.countriesList = this.selectedCountry;
  }

  // Selected country Function
  getStatesList(event: any) {
    const selectedCountry = this.selectedCountry

    if (event.target.value === "select") {
      this.defaultState = "select";
      this.defaultZip = "select";
      this.defaultStore = "select";
      this.statesList = [];
      this.zipCodesList = [];
      this.storesList = [];
      return;
    }
    
    if (selectedCountry !== null) {
      this.defaultState = "select";
      this.defaultZip = "select";
      this.defaultStore = "select";
      this.statesList = this.selectedtState;
    }
  }

  // Selected state Function
  getZipCodesList(event: any) {
    const selectedState = this.selectedtState;
    if (event.target.value === "select") {
      this.defaultZip = "select";
      this.defaultStore = "select";
      this.zipCodesList = [];
      this.storesList = [];
      return;
    }
    
    if (selectedState != null) {
      this.defaultZip = "select";
      this.defaultStore = "select";
      this.zipCodesList = this.selectedZipcode;
    }
  }

  // Selected state Function
  getStoresList(event: any) {
    const selectedZip = this.selectedZipcode;
    if (event.target.value === "select") {
      this.defaultStore = "select";
      this.storesList = [];
      return;
    }
    if (selectedZip !== null) {
      this.defaultStore = "select";
      this.storesList = this.selectedStores;
    }
  }

  // Selected store Function
  // fetchStoreData(event: any) {
  //   if (this.selectedStore) {
  //     this.usecasesList = this.defaultUsecasesList;
  //   }
  // }

  selectUsecase(event:any) {
    this.selectedUsecase = event.target.value;
  }

  private initializeCanvas(): void {
    this.ctx = this.peopleCountHeatMapCanvas.nativeElement.getContext("2d")!;
    this.peopleCountHeatMapCanvas.nativeElement.width = this.canvasWidth;
    this.peopleCountHeatMapCanvas.nativeElement.height = this.canvasHeight;
    this.ctx.strokeStyle = "#a81d9d";
    this.ctx.lineWidth = 2;
  }

  private registerCanvasEvents(): void {
    this.peopleCountHeatMapCanvas.nativeElement.addEventListener("mousedown", this.onMouseDown.bind(this));
    this.peopleCountHeatMapCanvas.nativeElement.addEventListener("mousemove", this.onMouseMove.bind(this));
    this.peopleCountHeatMapCanvas.nativeElement.addEventListener("mouseup", this.onMouseUp.bind(this));
  }

  onMouseDown(event: MouseEvent): void {
    this.isDrawing = true;
    this.x_min = event.offsetX;
    this.y_min = event.offsetY;
  }

  onMouseMove(event: MouseEvent): void {
    if (!this.isDrawing) return;
    this.x_max = event.offsetX;
    this.y_max = event.offsetY;
    this.drawROI();
  }

  onMouseUp(): void {
    this.isDrawing = false;
    if (this.imageWidth !== undefined && this.imageHeight !== undefined) {
      const startX = Math.max(
        0,
        Math.min(Math.round(this.x_min / this.scaleX), this.imageWidth)
      );
      const startY = Math.max(
        0,
        Math.min(Math.round(this.y_min / this.scaleY), this.imageHeight)
      );
      const endX = Math.min(
        this.imageWidth,
        Math.max(Math.round(this.x_max / this.scaleX), 0)
      );
      const endY = Math.min(
        this.imageHeight,
        Math.max(Math.round(this.y_max / this.scaleY), 0)
      );

      // Ensure coordinates are normalized (start < end)
      const roi: ROI = {
        x_min: Math.min(startX, endX),
        y_min: Math.min(startY, endY),
        x_max: Math.max(startX, endX),
        y_max: Math.max(startY, endY),
      };

      this.selectedConfigROI.x_min = roi.x_min;
      this.selectedConfigROI.y_min = roi.y_min;
      this.selectedConfigROI.x_max = roi.x_max;
      this.selectedConfigROI.y_max = roi.y_max;

      // Save the ROI and redraw
      this.selectedROIs.push(roi);
      this.drawROI();
      this.clearROIs();
    }
  } 
  private drawROI(): void {
    if (!this.ctx) return;

    // Clear the canvas before redrawing
    this.clearCanvas();

    // Draw all previously selected ROIs
    for (const roi of this.selectedROIs) {
      this.ctx.beginPath();
      this.ctx.strokeStyle = "#bd1793";
      this.ctx.rect(
        roi.x_min * this.scaleX,
        roi.y_min * this.scaleY,
        (roi.x_max - roi.x_min) * this.scaleX,
        (roi.y_max - roi.y_min) * this.scaleY
      );
      this.ctx.stroke();
    }

    // Draw the ROI being currently drawn
    if (this.isDrawing) {
      this.ctx.beginPath();
      this.ctx.strokeStyle = "#ff0000";
      this.ctx.rect(
        this.x_min,
        this.y_min,
        this.x_max - this.x_min,
        this.y_max - this.y_min
      );
      this.ctx.stroke();
    }
  }

  private clearCanvas(): void {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    }
  }

  clearROIs(): void {
    this.selectedROIs = [];
  }

  onSubmit() {
    this.isSubmitted = true;
    if (
      this.selectedConfigROI.x_min == undefined ||
      this.selectedConfigROI.x_max == undefined ||
      this.selectedConfigROI.y_min == undefined ||
      this.selectedConfigROI.y_max == undefined ||
      this.selectedConfigROI.x_min <= 0 ||
      this.selectedConfigROI.x_max <= 0 ||
      this.selectedConfigROI.y_min <= 0 ||
      this.selectedConfigROI.y_max <= 0
    )
      return;
    this.isLoading = true;
  }

  fetchUsecases(event: any) {
    
  }

}