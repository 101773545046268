import { Injectable, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpRequest
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment.dev";
// import { environment } from "src/environments/environment.qa";

@Injectable({
  providedIn: "root",
})
export class StoreInsightService implements OnInit {
  // Common Services
  apiAuthBaseUrl = environment.apiAuthUrl;
  apiUtilityUrl = environment.apiAuthUrl;
  apiNotificationUrl = environment.apiAuthUrl;

  // Pitaya Services
  apiOrgManagementService = environment.pitayaOrgManagementService;
  apiSharedService = environment.pitayaSharedService;

  customerAnalysisBaseUrl = environment.customerAnalysisBaseUrl;

  selectedUserRole: any;
  store_id = "7a9b5b2f-5f68-4f2b-8123-abcdef123456";
  constructor(private http: HttpClient) {}
  ngOnInit(): void {}

  public getCustomerAnalyticsData(selectedDate: any): Observable<any> {
    return this.http.get<any[]>(`${this.customerAnalysisBaseUrl}app-CustomerAnalytics/checkout-queue/${this.store_id}/?date=${selectedDate}`);
  }

  public getDisplayEffectivenessData(selectedDate: any): Observable<any> {
    return this.http.get<any[]>(`${this.customerAnalysisBaseUrl}app-CustomerAnalytics/display-engagement/${this.store_id}/?date=${selectedDate}`);
  }

  public getActiveROIHeatmapInfo(selectedDate: any): Observable<any> {
    return this.http.get<any[]>(`${this.customerAnalysisBaseUrl}app-CustomerAnalytics/heat-map/${this.store_id}/?date=${selectedDate}`);
  }

  public getPredefinedROIInfo(selectedDate: any, defaultROIs: any): Observable<any> {
    let data = { store_id: this.store_id, selectedRoi: defaultROIs };
    const url = `${this.customerAnalysisBaseUrl}app-CustomerAnalytics/heat-map-selected-post/${this.store_id}/?date=${selectedDate}`;
    return this.http.post(url, data);
  }

  public getSelectedROIInfo(selectedDate: any, selectedRoi: any): Observable<any> {
    let data = { store_id: this.store_id, selectedRoi: selectedRoi };
    const url = `${this.customerAnalysisBaseUrl}app-CustomerAnalytics/heat-map-selected-post/${this.store_id}/?date=${selectedDate}`;
    return this.http.post(url, data);
  }

  public getCountriesData(): Observable<any> {
    return this.http.get<any[]>(`${this.apiOrgManagementService}/countries/list`);
  }

  public getStatesData(country_Id: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiOrgManagementService}/states/list?country_Id=${country_Id}`
    );
  }

  public getCitiesData(country_Id: any, state_Id: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiOrgManagementService}/cities/list?country_Id=${country_Id}&state_Id=${state_Id}`
    );
  }

  public getZipCodeData(stateId: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiOrgManagementService}/master/get-zipcode/${stateId}`
    );
  }

  public getStoresData(zipCode: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiSharedService}/stores/get-store-by-zipcode?zipCode=${zipCode}`
    );
  }

  public getStoreSolutionsData(): Observable<any> {
    // const user_id = localStorage.getItem("user_id");
    this.selectedUserRole = this.http.get(`${this.apiSharedService}/store-insights/solutions`);
    return this.selectedUserRole;
  }

  public getWidgetData(store_id: any, widget: any): Observable<any> {
    let data = { store_id: store_id };
    const url = `${this.apiSharedService}/store-insights/${widget}`;
    return this.http.post(url, data);
  }

  public getStoreFootfallData(store_id: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiSharedService}/store-insights/footfall?store_id=${store_id}`
    );
  }

  public getStoreOosData(store_id: any): Observable<any> {
    let headers = new HttpHeaders();
    let headers1 = headers.append("Access-Control-Allow-Origin", "*");
    const url = `${this.apiSharedService}/store-insights/oos`;
    return this.http
      .post(
        url,
        { store_id },
        { headers: headers1 }
      )
      .pipe(catchError(this.handleError));
  }

  public getStoreReceptionData(store_id: any): Observable<any> {
    let headers = new HttpHeaders();
    let headers1 = headers.append("Access-Control-Allow-Origin", "*");
    const url = `${this.apiSharedService}/store-insights/customer_reception`;
    return this.http
      .post(
        url,
        { store_id },
        { headers: headers1 }
      )
      .pipe(catchError(this.handleError));
  }

  public getStoreAlertData(store_id: any): Observable<any> {
    let headers = new HttpHeaders();
    let headers1 = headers.append("Access-Control-Allow-Origin", "*");
    const url = `${this.apiSharedService}/store-insights/alert`;
    return this.http
      .post(url, { store_id }, { headers: headers1 })
      .pipe(catchError(this.handleError));
  }

  public getStoreAssetProtectionData(storeId: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiSharedService}/store-insights/assetprotection?store_id=${storeId}`
    );
  }

  public getSelfCheckoutData(storeId: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiSharedService}/store-insights/selfcheckout?store_id=${storeId}`
    );
  }

  getCameraDataForHeatMap(storeId: any, useCaseIdentifier: any) {
    return this.http.get(`${this.apiSharedService}/cameras/list?StoreId=${storeId}&usecaseIdentifier=${useCaseIdentifier}`);
  }

  public getHeatMapsData(payload: any, today: boolean): Observable<any> {
    /// we need to pass the dynamic values once date filter is implemented in UI
    const url = `${this.apiSharedService}/store-insights/people-movement-map?today=${today}&start_date=2024-03-20&end_date=2024-03-21`;
    return this.http.post(url, payload);
  }

  getPathwayDataFromJson() {
    const url = `../../assets/mock-json/peoples_pathway.json`;
    return this.http.get<any[]>(url);
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(error);
    }
    return throwError("Something bad happened; please try again later.");
  }
}
