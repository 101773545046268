import { Injectable, Injector, Inject } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector, // Use Injector instead of direct injection
    @Inject("ALLOWED_URLS") private allowedUrls: string[]
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has('X-Skip-Interceptor')) {
      const headers = request.headers.delete('X-Skip-Interceptor');
      const modifiedRequest = request.clone({ headers });
      return next.handle(modifiedRequest);
    }

    if (this.isAllowedUrl(request.url)) {
      const authService = this.injector.get(AuthService); // Lazily load AuthService
      const token = authService.getAccessToken();

      if (token !== null) {
        const headers = request.headers.set("Authorization", `Bearer ${token}`);
        const modified = request.clone({ headers });
        return next.handle(modified);
      }
    }

    return next.handle(request);
  }

  private isAllowedUrl(url: string): boolean {
    return this.allowedUrls.some((pattern) => new RegExp(pattern).test(url));
  }
}
