<div class="d-flex justify-content-between mt-4 px-4">
    <div class="store-search-box px-0">
        <div class="card search-box card-box">
          <div class="d-flex br-3 justify-content-start px-3">
            <div class="search-item d-flex me-4 py-3">
              <label>Country</label>
              <div class="search-selector">
                <select [disabled]="!isCountriesAllowed" class="store-search" (change)="getStatesList($event)" [(ngModel)]="defaultCountry">
                  <option selected value="select">- Select -</option>
                  <option *ngFor="let country of countriesList | orderBy:'country.countryName'"
                    value="{{country.countryUID}}">
                    {{country.countryName}}
                  </option>
                </select>
              </div>
            </div>
    
            <div class="search-item d-flex me-4 py-3">
              <label>State</label>
              <div class="search-selector">
                <select [disabled]="!isStatesAllowed"class="store-search" (change)="getZipCodesList($event)" [(ngModel)]="defaultState">
                  <option selected value="select">- Select -</option>
                  <option *ngFor="let state of statesList | orderBy:'state.stateName'" value="{{state.stateUID}}">
                    {{state.stateName}}
                  </option>
                </select>
              </div>
            </div>
    
            <div class="search-item d-flex me-4 py-3">
              <label>Zip Code</label>
              <div class="search-selector">
                <select [disabled]="!isZipCodeAllowed" class="store-search" (change)="getStoresList($event)" [(ngModel)]="defaultZip">
                  <option selected value="select">- Select -</option>
                  <option *ngFor="let zip of zipCodesList | orderBy:'name'">{{zip.zipCode}}
                  </option>
                </select>
              </div>
            </div>
    
            <div class="search-item d-flex me-4 py-3">
              <label>Store</label>
              <div class="search-selector">
                <select [disabled]="!isStoresAllowed" class="store-search" [(ngModel)]="defaultStore">
                  <option selected value="select">- Select -</option>
                  <option value="{{store.storeId}}" *ngFor="let store of storesList | orderBy:'storeName'">
                    {{store.storeName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
<div class="d-flex justify-content-between mt-4 px-4">
    <div class="roi-config-body d-flex justify-content-center px-2">
        <div class="roi-config-inner">
            <div class="analytics-map px-2">            
                <canvas #peopleCountHeatMapCanvas class="draw-canvas" 
                    style="background: url({{defaultHeatmapImage}}) no-repeat center center;"></canvas>
            </div>
        </div>
        <div class="config-form px-4 mt-3">
            <h5 class="mb-3 mt-3 text-center">ROI Configurations</h5>
            <form [formGroup]="configurationForm" (ngSubmit)="onSubmit()" class="usecase-config-form">
              <div class="d-flex justify-content-center mb-3">
                <div class="usecase-selector-box">
                  <label for="xMin">Select Usecase: <sup>*</sup></label>
                  <select class="icon-arrow usecase-selector" id="selectedUsecaseUID" formControlName="usecaseUID" (change)="selectUsecase($event)" [(ngModel)]="selectedUsecase">
                    <option [disabled]="true" value="">- Select Usecase -</option> 
                    <option *ngFor="let usecase of defaultUsecasesList" [value]="usecase.usecaseUID">{{usecase.usecaseName}}</option>
                  </select>
                </div>
              </div>
                <div class="d-flex justify-content-center mb-3">
                    <div class="dimension-input usecase-form-group">
                        <label for="xMin">X Min: <sup>*</sup></label>
                        <input type="text" id="xMin" name="xMin" required="true" [(ngModel)]="selectedConfigROI.x_min"
                        [ngModelOptions]="{standalone: true}" class="form-control" placeholder="X Min" disabled>
                    </div>
                    <div class="dimension-input usecase-form-group">
                        <label for="xMax">X Max: <sup>*</sup></label>
                        <input type="text" id="xMax" name="xMax" required="true" [(ngModel)]="selectedConfigROI.x_max"
                        [ngModelOptions]="{standalone: true}" class="form-control" placeholder="X Max" disabled>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-4">
                    <div class="dimension-input usecase-form-group">
                        <label for="xMax">Y Min: <sup>*</sup></label>
                        <input type="text" id="xMax" name="xMax" required="true" [(ngModel)]="selectedConfigROI.y_min"
                            [ngModelOptions]="{standalone: true}" class="form-control" placeholder="X Max" disabled>
                    </div>
                    <div class="dimension-input usecase-form-group">
                        <label for="xMax">Y Max: <sup>*</sup></label>
                        <input type="text" id="xMax" name="xMax" required="true" [(ngModel)]="selectedConfigROI.y_max"
                            [ngModelOptions]="{standalone: true}" class="form-control" placeholder="X Max" disabled>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-4">
                  <div class="usecase-form-descrption">
                      <label for="description">Description</label>
                      <textarea id="description" required="true" formControlName="roiDescription"
                          class="form-control" placeholder="Description" rows="3"></textarea>
                  </div>
              </div>
                <button type="submit" class="btn btn-add">Submit</button>
              </form>
        </div>
    </div>
</div>

