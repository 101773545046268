<div id="sidebar" class="sidebar">
  <div class="brand brand-img">
    <img src="../../../../assets/logo-white.png" alt="">
  </div>
  <nav>
    <ul class="list-unstyled components mb-2">
      <li routerLinkActive="active" *ngIf="isMenuEnabled('tasks') && isStoreUser">
        <a class="home" routerLink="/my-tasks">
          <div class="nav-icon"><img src="../../../../assets/icons/menu-tasks.svg" alt=""></div> My Tasks
        </a>
      </li>
      <li routerLinkActive="active"  *ngIf="isMenuEnabled('store-insights') && isStoreUser">
        <a class="insight">
          <div class="nav-icon"><img src="../../../../assets/icons/menu-store-insights.svg" alt=""></div> Store Insights
        </a>
      </li>
      <!-- <li routerLinkActive="active"  *ngIf="isMenuEnabled('store-insights') && isStoreUser">
        <a class="insight" routerLink="/roi-configurations">
          <div class="nav-icon"><img src="../../../../assets/icons/settings.svg" alt=""></div> ROI Configurations
        </a>
      </li> -->
      <!-- <li routerLinkActive="active" *ngIf="isMenuEnabled('cases') && isStoreUser">
        <a class="insight" routerLink="/case-management">
          <div class="nav-icon">
            <img src="../../../../assets/icons/case_management.svg" alt="" />
          </div>
          Case Management
        </a>
      </li> -->
    </ul>
  </nav>
  <nav class="super">
    <ul class="list-unstyled components mb-2">
      <li routerLinkActive="active">
        <a class="subnav-item" routerLink="/profile-page">
          <img src="../../../../assets/icons/profile-white.svg" alt="Profile Image" />
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="isMenuEnabled('orgs') && isCSOrgAdmin">
        <a class="subnav-item" routerLink="/configuration">
          <img src="../../../../assets/icons/settings.svg" alt="setting Image" />
        </a>
      </li>
      <li>
        <a class="subnav-item" (click)="logout()" routerLink="/"><img src="../../../../assets/icons/power.svg"
            alt="Logout Image" /></a>
      </li>
    </ul>
  </nav>
</div>