import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError, BehaviorSubject, Subject, zip } from "rxjs";
import { catchError } from "rxjs/operators";
// import { environment } from "src/environments/environment.dev";
import { environment } from "src/environments/environment.dev";
import { ROI } from "../components/pages/configuration/store-setup/usecase/usecase-config/usecase-config.component";

import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class ConfigurationService {
  // API Key
  apiKey: string = "";

  // Common Services
  apiAuthBaseUrl = environment.apiAuthUrl;
  apiUtilityUrl = environment.apiAuthUrl;
  apiNotificationUrl = environment.apiAuthUrl;

  // Pitaya Services
  apiOrgManagementService = environment.pitayaOrgManagementService;
  apiSharedService = environment.pitayaSharedService;
  apiUserManagementService = environment.pitayaUserManagementService;

  apiDeviceDeployService = environment.deviceDeployService;

  notificationAuthToken = environment.notificationAuthToken;


  public itemsPerPage = 10;
  public pageSize = 1;
  private roiConfigurations: ROI[] = [];
  private roiConfigurationsSubject: BehaviorSubject<ROI[]> =
    new BehaviorSubject<ROI[]>(this.roiConfigurations);

  private imageDimensionsSubject: BehaviorSubject<ROI[]> = new BehaviorSubject<
    ROI[]
  >(this.roiConfigurations);

  private submittedFormSource = new Subject<boolean>();
  constructor(private http: HttpClient, private authService: AuthService) {
    this.apiKey =  this.authService.getApiKey();
  }

  header = this.getHeaders();
  public getHeaders() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let headers1 = headers.append("Access-Control-Allow-Origin", "*");
    return headers1;
  }

  addOrUpdateROIConfiguration(roiConfiguration: ROI) {
    const existingIndex = this.roiConfigurations.findIndex(
      (roi) => roi.type === roiConfiguration.type
    );

    if (existingIndex !== -1) {
      this.roiConfigurations[existingIndex] = roiConfiguration; // Update existing ROI configuration
    } else {
      this.roiConfigurations.push(roiConfiguration); // Add new ROI configuration
    }

    this.roiConfigurationsSubject.next(this.roiConfigurations);
  }

  setImageDimensions(dimensions: any) {
    this.imageDimensionsSubject.next(dimensions);
  }

  getImageDimensions(): BehaviorSubject<ROI[]> {
    return this.imageDimensionsSubject;
  }

  getROIConfigurations(): BehaviorSubject<ROI[]> {
    return this.roiConfigurationsSubject;
  }

  resetROIConfigurations() {
    this.roiConfigurations = [];
    this.roiConfigurationsSubject.next(this.roiConfigurations);
  }

  removeLastROI() {
    this.roiConfigurations.pop(); // Remove the last element from the array
    this.roiConfigurationsSubject.next(this.roiConfigurations);
  }

  drawROIConfigurations() {
    this.roiConfigurationsSubject.next(this.roiConfigurations);
  }

  submittedFormData$ = this.submittedFormSource.asObservable();
  setActiveItem(status: boolean) {
    this.submittedFormSource.next(status);
  }

  roisUpdated = this.roiConfigurationsSubject.asObservable();

  public getCountriesData(): Observable<any> {
    return this.http.get<any[]>(`${this.apiOrgManagementService}/countries/list`);
  }
  
  // public getResourceData(): Observable<any> {
  //   return this.http.get<any[]>(this.apiBaseUrl + "fetch-all-resources");
  // } 

  public getStatesData(country_Id: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiOrgManagementService}/states/list?country_Id=${country_Id}`
    );
  }

  public getCitiesData(country_Id: any, state_Id: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiOrgManagementService}/cities/list?country_Id=${country_Id}&state_Id=${state_Id}`);
  }

  public getZipData(state_Id: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiOrgManagementService}/master/get-zipcode/${state_Id}`
    );
  }

  public getTimezoneData(): Observable<any> {
    return this.http.get<any[]>(`${this.apiOrgManagementService}/master/get-timezone`);
  }

  public getAlertModes(): Observable<any> {
    return this.http.get<any[]>(`${this.apiSharedService}/stores/get-alert-modes`);
  }

  public getAlertModesByStore(store_Id: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiSharedService}/stores/get-alert-modes?StoreId=${store_Id}`
    );
  }

  public getOrgNameUnigue(tenantName: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiOrgManagementService}/orgs/is-org-exists?tenantName="${tenantName}`
    );
  }

  // get camera unique
  public getCameraNameUnigue(
    cam_name: any,
    tenant_Id: any,
    store_Id: any
  ): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiSharedService}/cameras/is-camera-exists?camname=${cam_name}&tenantId=${tenant_Id}&storeId=${store_Id}`
    );
  }
  // get device unique
  public getDeviceNameUnigue(deviceName: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiSharedService}/devices/is-device-exists?deviceName=${deviceName}`
    );
  }
  // get device mac unique
  public getDeviceMacUnigue(device_mac: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiSharedService}/devices/is-device-mac-exists?deviceMacAddress=${device_mac}`
    );
  }

  public getStoreNameUnigue(storeName: any, tenantId: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiSharedService}/stores/is-store-exists?storeName=${storeName}&tenantId=${tenantId}`
    );
  }

  public getStoreNameUnigueById(
    storeName: any,
    tenantId: any,
    storeId: any
  ): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiSharedService}/stores/is-store-exists?storeName=${storeName}&tenantId=${tenantId}&storeId=${storeId}`
    );
  }

  public getEmailCheckUnique(email: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiUserManagementService}/user/is-email-unique?Email=${email}`
    );
  }

  public getContactCheckUnique(mobileNumber: any): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiUserManagementService}/user/is-contact-unique?PhNo=" + ${mobileNumber}`
    );
  }

  // get organizations
  public getOrgData(
    pageSize: number,
    currentPage: number,
    status: string
  ): Observable<any> {
    const url = `${this.apiOrgManagementService}/orgs/list?PageSize=${pageSize}&Pagenumber=${currentPage}&status=${status}`;
    return this.http.get<any[]>(url).pipe(catchError(this.handleError));
      
  }

  // get stores
  getStoresData(
    tenantId: any,
    pageSize: number,
    currentPage: number,
    status: string
  ) {
    const url = `${this.apiSharedService}/stores/list?pageNumber=${currentPage}&PageSize=${pageSize}&tenantId=${tenantId || ""}&status=${status}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  // get profile
  getProfileData(UserId: any, tenantId: any) {
    return this.http.get<any[]>(`${this.apiSharedService}/profile/get/${UserId}`).pipe(catchError(this.handleError));
  }

  // upload profile image

  public removeProfileImage(): Observable<any> {
    return this.http.get(`${this.apiSharedService}/profile/remove-profile-picture`).pipe(catchError(this.handleError));
  }

  public removeProfileBanner(): Observable<any> {
    return this.http.get(`${this.apiSharedService}/profile/remove-profile-banner`).pipe(catchError(this.handleError));
  }

  public updateProfileImage1(userId: any): Observable<any> {
    return this.http.get(`${this.apiSharedService}/profile/remove-profile-picture/${userId}`).pipe(catchError(this.handleError));
  }

  public uploadProfileImage(file: File, tenantId: any, userId: any) {
    const formData = new FormData();
    formData.append("files", file); // 'file' should match the server's expected file field name
    formData.append("id", "profile-pictures");
    formData.append("subdir", `${tenantId}/${userId}`);
    formData.append("slug", "profile_picture");
    // You may need to set additional headers if required by your server
    const url = `${this.apiUtilityUrl}upload-file/`;
    return this.http.post(url, formData).pipe(catchError(this.handleError));
  }
  public uploadProfileBanner(file: File, tenantId: any, userId: any) {
    const formData = new FormData();
    // let data = {
    //   "files" : file,
    //   "id" : "profile-banners",
    //   "subdir" : tenantId+"/"+userId+"/banner_image",
    //   "slug" : "profile_banner",
    //   "tenantid" : tenantId,
    //   "userid" : userId,
    // }
    formData.append("files", file);
    formData.append("id", "profile-pictures");
    formData.append("subdir", `${tenantId}/${userId}/banner_image`);
    formData.append("slug", "profile_picture");
    formData.append("tenantid", tenantId);
    formData.append("loginuid", userId);
    const url = `${this.apiSharedService}/profile/upload-profile-banner/`;
    return this.http.post(url, formData).pipe(catchError(this.handleError));
  }

  public postProfileData(imageUrl: any, userId: any) {
    const url = `${this.apiSharedService}/profile/upload-profile-picture/${userId}`;
    return this.http.post(url, imageUrl).pipe(catchError(this.handleError));
  }

  // get devices
  getDevicesData(
    storeId: any,
    tenantId: any,
    pageSize: number,
    currentPage: number,
    status: string
  ) {
    const url = `${this.apiSharedService}/devices/list?pageNumber=${currentPage}&PageSize=${pageSize}&StoreId=${storeId}&tenantId=${tenantId}&status=${status}`;
    return this.http.get(url);
  }

  // get devicestype
  getDeviceType() {
    return this.http.get<any[]>(
      `${this.apiSharedService}/devices/get-device-types`
    );
  }

  //create device
  public createNewDevice(data: any): Observable<any> {
    const url = `${this.apiSharedService}/devices/create`
    return this.http.post(url, data, {headers: this.header}).pipe(catchError(this.handleError));
  }

  // Update the Device
  public updateNewDevice(deviceId: any, data: any): Observable<any> {
    let url = `${this.apiSharedService}/devices/update/${deviceId}`;
    return this.http.put(url, data, { headers: this.header }).pipe(catchError(this.handleError));
  }

  // device deploy
  public deviceDeploy(IOTDeviceHubName: any): Observable<any> {
    let url = `${this.apiDeviceDeployService}/iot-devices/${IOTDeviceHubName}/deploy-usecases`;
    const data = { IOTDeviceHubName: IOTDeviceHubName };
    return this.http.post(url, data, { headers: this.header }).pipe(catchError(this.handleError));
  }

  // Create New Organization
  public createNewOrg(data: any): Observable<any> {
    const url = `${this.apiOrgManagementService}/orgs/create`;
    return this.http .post(url, data, { headers: this.header })
      .pipe(catchError(this.handleError));
  }

  // Get Organization Data
  public fetchOrgData(tenant_id: any): Observable<any> {
    return this.http.get<any[]>(`${this.apiOrgManagementService}/orgs/get?tenantId=${tenant_id}`);
  }

  // Update Organization Data
  public updateNewOrg(tenant_id: any, data: any): Observable<any> {
    let url = `${this.apiOrgManagementService}/orgs/update?tenantId=${tenant_id}`;
    return this.http.put(url, data, { headers: this.header }).pipe(catchError(this.handleError));
  }

  // Delete the Organization
  public deleteOrganisation(tenantId: any): Observable<any> {
    const url = `${this.apiOrgManagementService}/orgs/delete?tenantId=${tenantId}`;
    return this.http.delete(url, tenantId).pipe(catchError(this.handleError));
  }

  // Create the Store
  public createStore(data: any): Observable<any> {
    const url = `${this.apiSharedService}/stores/create`;
    return this.http.post(url, data, {headers: this.header}).pipe(catchError(this.handleError));
  }

  // Get Store Data
  public fetchStoreData(tenant_id: any): Observable<any> {
    return this.http.get<any[]>(`${this.apiSharedService}/stores/list?tenantId=${tenant_id}`);
  }

  // Update the Organization
  public updateStore(storeId: any, data: any): Observable<any> {
    let url = `${this.apiSharedService}/stores/update/${storeId}`;
    return this.http.put(url, data, { headers: this.header }).pipe(catchError(this.handleError));
  }

  // Delete the Store
  public deleteStore(storeId: any): Observable<any> {
    const url = `${this.apiSharedService}/stores/delete/${storeId}`;
    return this.http.delete(url, { headers: this.header }).pipe(catchError(this.handleError));
  }

  public getAllUsersData(status: string) {
    return this.http.get<any[]>(`${this.apiUserManagementService}/user/list?status=${status}`);
  }

  // Get User Data
  public getUsersData(storeId: any, tenantId: any, status: string) {
    return this.http.get<any[]>( `${this.apiUserManagementService}/user/list?storeId=${storeId}&tenantId=${tenantId}&status=${status}`);
  }


  public getAlertUsersData(storeId: any, currentPage: number, pageSize: number) {
    return this.http.get<any[]>(`${this.apiUserManagementService}/user/list?storeId=${storeId}&Pagesize=${pageSize}&Pagenumber=${currentPage}`);
  }

  // Get User Data
  public getUserData(userId: any) {
    return this.http.get<any[]>(`${this.apiUserManagementService}/user/list?userId=${userId}`);
  }

  public getAllResourseData(org_id: any): Observable<any> {
    const data = { org_id: org_id };
    return this.http.get(`${this.apiAuthBaseUrl}/resources/list/`, {headers: this.header.append("X-API-Key", this.apiKey)}).pipe(catchError(this.handleError));
  }

  public deleteRoleInUserManagement(role_id: any): Observable<any> {
    const url = `${this.apiUserManagementService}/user/update-or-delete-role/${role_id}`;
    return this.http.delete(url, {headers: this.header}).pipe(catchError(this.handleError));
  }

  public updateRoleInUserManagement(role_id: any, role_name: any): Observable<any> {
    const url = `${this.apiUserManagementService}/user/update-or-delete-role/${role_id}`;
    return this.http.put(url, { role_name: role_name }, { headers: this.header }).pipe(catchError(this.handleError));
  }

  public getAllRolesDataByOrgId(org_id: any): Observable<any> {
    return this.http.get(`${this.apiUserManagementService}/user/fetch-all-roles/${org_id}`).pipe(catchError(this.handleError));
  }

  // Below Functions are not used anywhere
  public deleteRole1(org_id: any, role_id: any): Observable<any> {
    const url = `${this.apiAuthBaseUrl}/auth/delete-role?org_id=${org_id}&role_id=${role_id}`;
    return this.http.post(url, org_id).pipe(catchError(this.handleError));
  }

  public createOrEditRollsData(data: any): Observable<any> {
    const url = `${this.apiAuthBaseUrl}/auth/create-or-edit-role/`;
    return this.http.post(url, data, {headers: this.header}).pipe(catchError(this.handleError));
  }

  public createOrEditPolicyData(data: any): Observable<any> {
    const url = `${this.apiAuthBaseUrl}/auth/create-or-edit-policy/`;
    return this.http
      .post(url, data, {
        headers: this.header,
      })
      .pipe(catchError(this.handleError));
  }
  // Above functions are not used anywhere

  public createOrEditRollspolicy(data: any): Observable<any> {
    const url = `${this.apiAuthBaseUrl}/auth/create-or-edit-role-policy/`;
    return this.http
      .post(url, data, {
        headers: this.header,
      })
      .pipe(catchError(this.handleError));
  }

  public createRoleInPitaya(data: any): Observable<any> {
    const url = `${this.apiUserManagementService}/user/create-role`;
    return this.http
      .post(url, data, {
        headers: this.header,
      })
      .pipe(catchError(this.handleError));
  }

  public createOrEditInheritedRole(data: any): Observable<any> {
    const url = `${this.apiAuthBaseUrl}/auth/create-or-edit-inherited-role/`;
    return this.http
      .post(url, data, {
        headers: this.header,
      })
      .pipe(catchError(this.handleError));
  }

  public deleteRole(role_id: any): Observable<any> {
    const url = `${this.apiAuthBaseUrl}/roles/delete-role-relation/${role_id}`;
    return this.http
      .delete(
        url,{headers: this.header.append("X-API-Key", this.apiKey),
        }
      )
      .pipe(catchError(this.handleError));
  }

  public createRoleRelation(data: any): Observable<any> {
    const url = `${this.apiAuthBaseUrl}/roles/create-role-relation/`;
    return this.http
      .post(url, data, {
        headers: this.header.append("X-API-Key", this.apiKey),
      })
      .pipe(catchError(this.handleError));
  }
  public deleteInheritedRole(data: any): Observable<any> {
    const url = `${this.apiAuthBaseUrl}/auth/delete-inherited-role123/`;
    return this.http
      .post(url, data, {
        headers: this.header,
      })
      .pipe(catchError(this.handleError));
  }

  public fetchRole(role_id: any): Observable<any> {
    return this.http
      .get(`${this.apiAuthBaseUrl}/roles/role/${role_id}`, {
        headers: this.header.append("X-API-Key", this.apiKey),
      })
      .pipe(catchError(this.handleError));
  }

  public fetchInheritedRole(inherit_role_id: any): Observable<any> {
    return this.http
      .get(
        `${this.apiAuthBaseUrl}/inherittable/inherit/${inherit_role_id}`,
        {
          headers: this.header.append("X-API-Key", this.apiKey),
        }
      )
      .pipe(catchError(this.handleError));
  }

  // Create the user
  public createUser(data: any): Observable<any> {
    const url = `${this.apiUserManagementService}/user/create`;
    return this.http
      .post(url, data, { headers: this.header })
      .pipe(catchError(this.handleError));
  }

  // Update the User
  public updateUser(userId: any, data: any): Observable<any> {
    const url = `${this.apiUserManagementService}/user/update/${userId}`;
    return this.http.put(url, data, { headers: this.header }).pipe(catchError(this.handleError));
  }

  public updateRoleRelation(role_id: any, data: any): Observable<any> {
    const url = `${this.apiAuthBaseUrl}/roles/edit-role-relation/${role_id}`;
    return this.http
      .put(url, data, {headers: this.header.append("X-API-Key", this.apiKey)})
      .pipe(catchError(this.handleError));
  }

  // Delete the User
  public deleteUser(userId: number): Observable<any> {
    const url = `${this.apiUserManagementService}/user/delete/${userId}`;
    return this.http
      .delete(url, { headers: this.header })
      .pipe(catchError(this.handleError));
  }

  public forgotPassword(data: any): Observable<any> {
    const url = `${this.apiUserManagementService}/user/forgot-password`;
    return this.http
      .post(url, data, { headers: this.header })
      .pipe(catchError(this.handleError));
  }

  // Update the Camera
  public resetPassword(data: any): Observable<any> {
    const url = `${this.apiUserManagementService}/user/reset-password`;
    return this.http
      .put(url, data, { headers: this.header })
      .pipe(catchError(this.handleError));
  }
  public profileResetPassword(data: any): Observable<any> {
    const auth_token = localStorage.getItem("auth_token");
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", `Bearer ${auth_token}`);
    const url = `${this.apiSharedService}/profile/validate-passwords`;
    return this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
  }

  public profileResendOtp(): Observable<any> {
    let auth_token = localStorage.getItem("auth_token");
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", `Bearer ${auth_token}`);
    let url = `${this.apiSharedService}/profile/generate-otp`;
    return this.http
      .post(url, null, { headers: headers })
      .pipe(catchError(this.handleError));
  }

  public profileValidateOtp(data: any): Observable<any> {
    let auth_token = localStorage.getItem("auth_token");
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", `Bearer ${auth_token}`);
    let url = `${this.apiSharedService}/profile/update-password/`;
    return this.http
      .post(url, data, { headers: this.header })
      .pipe(catchError(this.handleError));
  }

  // /User/Delete?userId=166&isDeleted=true
  getStoreDeviceData(storeId: any, pageSize: number, currentPage: number) {
    return this.http.get<any[]>(
      `${this.apiSharedService}/devices/list?StoreId=${storeId}&Pagesize=${pageSize}&pageNumber=${currentPage}`);
  }

  // Commented it out because, not using anywhere
  getAllUseCaseData(storeId: any) {
    const url_type = "usecase-config";
    return this.http.get<any[]>(
      this.apiSharedService + url_type + "/get-usecases?storeId="+ storeId
    );
  }

  /// Commented it out because, not using anywhere
  getUseCaseData(pageSize: number, currentPage: number, storeId: any) {
    return this.http.get<any[]>(
      `${this.apiSharedService}/UseCaseMapping/Get?StoreId=${storeId}&Pagesize=${pageSize}&Pagenumber=${currentPage}`);
  }

  // Create the Camera
  public createCamera(data: any): Observable<any> {
    const url = `${this.apiSharedService}/cameras/create`;
    return this.http
      .post(url, data, {
        headers: this.header,
      })
      .pipe(catchError(this.handleError));
  }

  // Update the Camera
  public updateCamera(camUid: any, data: any): Observable<any> {
    const url = `${this.apiSharedService}/cameras/update/${camUid}`;
    return this.http
      .put(url, data, { headers: this.header })
      .pipe(catchError(this.handleError));
  }

  getCameraData(storeId: any, tenantId: any, status: string) {
    const pageSize = this.pageSize;
    const currentPage = 0;
    const url = `${this.apiSharedService}/cameras/list?pageNumber=${currentPage}&PageSize=${pageSize}&StoreId=${storeId}&tenantId=${tenantId}&status=${status}`;
    return this.http.get(url);
  }

  public deleteUsecase(ucConfigUID: any): Observable<any> {
    const status = false;
    const url =
      `${this.apiSharedService}/usecase-config/delete?ucConfigUID=${ucConfigUID}&status=${status}`;
    return this.http
      .delete(url, ucConfigUID)
      .pipe(catchError(this.handleError));
  }

  public fileUploadSubject = new BehaviorSubject<boolean>(false);
  fileUploadStatus = this.fileUploadSubject.asObservable();
  getFileUploadStatus(filestatus: boolean) {
    this.fileUploadSubject.next(filestatus);
  }

  // Usecase Mapping
  public createUseCaseMapping(usecaseType: any, data: any): Observable<any> {
    const url = `${this.apiSharedService}/usecase-config/create?usecaseType=${usecaseType}`;
    return this.http
      .post(url, data, {headers: this.header})
      .pipe(catchError(this.handleError));
  }

  // Usecase Mapping
  public updateUseCaseMapping(
    ucConfigUID: any,
    usecaseType: any,
    data: any
  ): Observable<any> {
    // let headers = new HttpHeaders();
    // let headers1 = headers.append("Access-Control-Allow-Origin", "*");
    let url = `
      ${this.apiSharedService}/usecase-config/update?ucConfigUID=${ucConfigUID}&usecaseType=${usecaseType}`;
    return this.http
      .put(url, data, {headers: this.header})
      .pipe(catchError(this.handleError));
  }

  useCaseCheckUnique(deviceId: any, cameraId: any, usecaseId: any) {
    return this.http.get<any[]>(
      `${this.apiSharedService}/usecase-config/is-usecasemap-exists?cameraId=${deviceId}&deviceId=${deviceId}&usecaseId=${usecaseId}`
    );
  }

  public uploadUsecaseConfigImage(file: File, tenantId: any, storeId: any, cameraId: any) {
    const formData = new FormData();
    formData.append("files", file); // 'file' should match the server's expected file field name
    formData.append("id", "usecase-pictures");
    formData.append("subdir",`${tenantId}/${storeId}/${cameraId}`); //tenantid/storeid/camMacAdd
    formData.append("slug","usecase_config");
    // You may need to set additional headers if required by your server
    const url = `${this.apiUtilityUrl}upload-file/`;
    return this.http.post(url, formData).pipe(catchError(this.handleError));
  }

  getAllUseCaseConfigData(
    currentPage: number,
    pageSize: number,
    storeId: any,
    tenantId: any,
    status: string
  ): Observable<any> {
    const data = {
      currentPage: currentPage,
      pageSize: pageSize,
      storeId: storeId,
      tenantId: tenantId,
    };
    return this.http.get(`${this.apiSharedService}/usecase-config/list?StoreId=${storeId}&tenantId=${tenantId}&status=${status}`)
      .pipe(catchError(this.handleError));
  }

  getUseCaseConfigData(
    UCConfigUID: number,
    UsecaseIdentifier: string
  ): Observable<any> {
    const data = {
      UCConfigUID: UCConfigUID,
      UsecaseIdentifier: UsecaseIdentifier,
    };
    return this.http
      .get(`${this.apiSharedService}/usecase-config/get?ucconfiguid=${UCConfigUID}&UsecaseIdentifier=${UsecaseIdentifier}`)
      .pipe(catchError(this.handleError));
  }

  alertCheckUnique(storeId: any, alertId: any, usecaseId: any, order: any) {
    return this.http.get<any[]>(
      `${this.apiSharedService}/alerts/is-alert-exists?storeId=${storeId}&alertId=${alertId}&usecaseuid=${usecaseId}&order=${order}`
    );
  }

  getUsecaseAlertData(storeId: any, tenantId: any, status: string) {
    const pageSize = this.pageSize;
    const currentPage = 1;
    return this.http.get<any[]>(`${this.apiSharedService}/alerts/list?StoreId=${storeId}&tenantId=${tenantId}&Pagesize=${pageSize}&Pagenumber=${currentPage}&status=${status}`);
  }

  public createNotifTemplate(data: any): Observable<any> {
    let auth_token = this.notificationAuthToken;
    
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${auth_token}`,
      'X-Skip-Interceptor': 'true'
    });
    let headers1 = headers.append("Access-Control-Allow-Origin", "*");

    return this.http
    .post(`${this.apiNotificationUrl}notification-templates/create`, data, {
      headers: headers1,
    })
    .pipe(catchError(this.handleError));
  }

  // Create the usecase alert
  public createUserAlert(data: any): Observable<any> {
    let headers = new HttpHeaders();
    let headers1 = headers.append("Access-Control-Allow-Origin", "*");
    const url = `${this.apiSharedService}/alerts/create`;
    return this.http
      .post(url, data, {
        headers: headers1,
      })
      .pipe(catchError(this.handleError));
  }
  // Update the usecase alert
  public updateUserAlert(
    alertUcUId: any,
    uniqueId: number,
    data: any
  ): Observable<any> {
    let headers = new HttpHeaders();
    let headers1 = headers.append("Access-Control-Allow-Origin", "*");
    const url = `${this.apiSharedService}/alerts/update/${alertUcUId}?uniqueId=${uniqueId}`;
    return this.http
      .put(url, data, { headers: headers1 })
      .pipe(catchError(this.handleError));
  }

  public deleteUserAlert(alertUcUId: any, uniqueId: number): Observable<any> {
    const isDeleted = true;
    const url =
      `${this.apiSharedService}/alerts/delete/${alertUcUId}?uniqueId=${uniqueId}&isDeleted=${isDeleted}`;
    return this.http.delete(url, alertUcUId).pipe(catchError(this.handleError));
  }

  public validateAuthToken(storeId: any, authToken: any): Observable<any> {
    const body = { storeId: storeId, authToken: authToken };
    let url = `${this.apiSharedService}/stores/update-hyptoken/${storeId}`;
    return this.http
      .post(url, body, { headers: this.header })
      .pipe(catchError(this.handleError));
  }

  public getUseCaseDynamicParams(
    tenantid: string,
    usecaseid: string
  ): Observable<any> {
    return this.http
      .get(
        `${this.apiSharedService}/alerts/fetch-dynamic-params?tenantid=${tenantid}&usecaseid=${usecaseid}`
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
    } else if (error.status === 400) {
      // Handle 400 Bad Request errors here
    } else {
    }
    return throwError(error);
  }
}