import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonService } from 'src/app/services/common.service';

export interface CustomCellRendererParams extends ICellRendererParams {
  gridIdentifier: string;
  isStoreHypIcon: boolean;
  isDelete: boolean;
  isDeviceDeploy: boolean;
  roleDelete: boolean;
  isEditAllowed: boolean;
  isDeleteAllowed: boolean;
}

@Component({
  selector: 'app-ag-cell-actions',
  template: `
    <div class="ag-cell-actions">
      <button class="btn-icon edit-icon" (click)="handleButtonClick('edit')" *ngIf="isEditAllowed">
        <img src="../../../../../assets/icons/editing.svg" alt="edit">
      </button>
      <button class="btn-icon delete-icon" *ngIf="!isDelete && isDeleteAllowed" [disabled]="!isEnabled" (click)="handleButtonClick('delete')">
        <img src="../../../../../assets/icons/delete.svg" alt="delete">
      </button>
      <button class="btn-icon delete-icon" *ngIf="roleDelete && isDeleteAllowed" (click)="handleButtonClick('delete')">
        <img src="../../../../../assets/icons/delete.svg" alt="delete">
      </button>
      <button class="btn-icon delete-icon" *ngIf="deviceDeploy" [disabled]="toBeDeployed === false"
              (click)="handleButtonClick('deploy')"><img src="../../../../../assets/icons/deploy.png" alt="">
      </button>
      <button class="btn-icon hyp hyps-active" *ngIf="isHypIcon && data?.hypStatus === true"
        (click)="handleButtonClick('hyperion')"><img src="../../../../../assets/icons/hype-active.png" />
      </button>
      <button class="btn-icon hyp hyp-inactive" *ngIf="isHypIcon && data?.hypStatus === null"
        disabled><img src="../../../../../assets/icons/hype-inactive.png" />
      </button>
      <button class="btn-icon hyp hyps-null" *ngIf="isHypIcon && data?.hypStatus === false"
        (click)="handleButtonClick('hyperion')"><img src="../../../../../assets/icons/hype-null.png" />
      </button>
    </div>
  `,
  styles:  [`
    .ag-cell-actions {
      display: flex;
    }

    .btn-icon {
      background-color: transparent;
      border: none;
      cursor: pointer;
      margin: 0px 4px;
    }

    .delete-icon[disabled] {
      opacity: 0.5;
    }
  `]
})

export class AgCellActionsComponent implements OnInit, ICellRendererAngularComp {

  constructor(private commonService: CommonService) {}

  handleButtonClick(action: string) {
    let actionDetails = {
      action: action,
      data: this.data
    }
    if (this.gridIdentifier === 'org') {
      this.commonService.emitOrgGridActionEvent(actionDetails);
    } else if (this.gridIdentifier === 'store') {
      this.commonService.emitStoreGridActionEvent(actionDetails);
    } else if (this.gridIdentifier === 'user') {
      this.commonService.emitUserGridActionEvent(actionDetails);
    } else if (this.gridIdentifier === 'camera') {
      this.commonService.emitCameraGridActionEvent(actionDetails);
    } else if (this.gridIdentifier === 'device') {
      this.commonService.emitDeviceGridActionEvent(actionDetails);
    } else if (this.gridIdentifier === 'userAlert'){
      this.commonService.emitUserAlertGridActionEvent(actionDetails);
    } else if (this.gridIdentifier === 'usecase'){
      this.commonService.emitUseCaseGridActionEvent(actionDetails);
    } else if (this.gridIdentifier === 'role'){
      this.commonService.emitRoleGridActionEvent(actionDetails);
    }
  }

  data: any;
  isEnabled: boolean = false;
  gridIdentifier: string;
  isHypIcon: boolean;
  isDelete: boolean;
  deviceDeploy: boolean;
  toBeDeployed: boolean;
  roleDelete: boolean = false;
  isEditAllowed: boolean;
  isDeleteAllowed: boolean;

  agInit(params: CustomCellRendererParams): void {
    this.data = params.data;
    this.isEnabled = params.data.status;
    this.gridIdentifier = params.gridIdentifier;
    this.isEditAllowed = params?.isEditAllowed ?? true;
    this.isDeleteAllowed = params?.isDeleteAllowed ?? true;
    this.isHypIcon = params.isStoreHypIcon;
    this.isDelete = params.isDelete;
    this.deviceDeploy = params.isDeviceDeploy;
    this.toBeDeployed = params.data.toBeDeployed;
    this.roleDelete = params.roleDelete;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
  
  ngOnInit(): void {
    
  }

}
