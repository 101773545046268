<div class="d-flex justify-content-between">
    <div class="heatmap-body">
        <div class="d-flex justify-content-between mb-3">
            <div class="roi-categories px-2">
                <!-- <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked>
                    <label class="form-check-label" for="inlineRadio1">Entrance - Checkout - Exit</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                    <label class="form-check-label" for="inlineRadio2">Customer Pathway Case 2</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3">
                    <label class="form-check-label" for="inlineRadio3">Customer Pathway Case 3</label>
                </div> -->
                <!-- <div class="heatmap-category">
                    <div class="usecase-form-group me-2 mb-2">
                        <select class="form-control" aria-label="Default select example">
                          <option value="default_ROI">Live Count</option>
                          <option value="draw_ROI">Draw ROI</option>
                        </select>
                      </div>
                </div> -->
                <button type="button" class="btn btn-edit" (click)="toggleHeatmapVisibility()" ngbTooltip="Draw ROI">
                    <span [hidden]="drawROIContent"><img src="../../../../../assets/draw.png" alt="draw"></span>
                    <span [hidden]="!drawROIContent"><img src="../../../../../assets/close.png" alt="Cancel"></span>
                </button>
            </div>
            <div class="roi-filters pt-2">
                <div class="daterange-filter justify-content-end d-flex">
                    <label class="heading">Select Dates: </label>
                      <div class="daterange-area">
                      <input
                        type="text"
                        ngxDaterangepickerMd
                        formControlName="selectedDateRange"
                        class="form-control daterange-input"
                        placeholder="Choose date"
                        (change)="onDateRangeSelected($event)" 
                        [showDropdowns]="true"
                        [showCancel]="true"/>
                        <span class="calendar-icon"></span>
                      </div>
                  </div>
            </div>
        </div>

        <div class="heatmap-people-count" [hidden]="drawROIContent">
            <div class="d-flex justify-content-center analytics-map px-2">            
                <div #peopleCountHeatMapChart id="peopleCountHeatMapChart" class="draw-canvas" style="width: 1200px; height: 500px;"></div>
            </div>
        </div>
        <div class="heatmap-people-count" [hidden]="!drawROIContent">
            <div class="d-flex justify-content-center analytics-map px-2">            
                <canvas #peopleCountHeatMapCanvas class="draw-canvas" 
                    style="background: url({{defaultHeatmapImage}}) no-repeat center center;"></canvas>
            </div>
        </div>
    </div>
    <div class="heatmap-analytics">
        <div class="people-count p-4 text-center my-2">
            <p>Time Duration: <span><strong>6AM to 9PM</strong></span></p>
            <h4>Total People Count</h4>
            <div class="d-flex justify-content-center text-center">
                <h2>1617</h2>
            </div>
        </div>
        <div class="people-count-by-hour">
            <div class="people-count p-2 my-2">
                <p>ID by Time of Day</p>
                <div id="peopleCountBarChart" style="width: 100%; height: 400px;"></div>
            </div>
        </div>
    </div>
</div>

