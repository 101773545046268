import { Component, Inject, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { StoreInsightService } from "src/app/services/store-insight.service";
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import jwt_decode from "jwt-decode";
import { CommonService } from "src/app/services/common.service";
import { AuthorizationService } from "src/app/services/authorization.service";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {
  isCSOrgAdmin: boolean = false;
  isStoreUser: boolean = false;
  menuData: any = {};
  enabledMenus: any;
  decodedToken: any;
  userRole: any;
  userRoleId: any;
  tenantId: any;
  loginUid: any;
  email: any;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private authService: AuthService,
    public storeInsightService: StoreInsightService,
    public router: Router,
    private commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.extractUserRoles();
    this.setUserType();
  }

  extractUserRoles() {
    try {
      const accessToken = localStorage.getItem("auth_token") ?? "default";
      const decodedToken: any = jwt_decode(accessToken);
      const extraDataObject = decodedToken.extra_data;
      this.tenantId = extraDataObject.org;
      this.userRoleId = decodedToken.role_id;
      this.userRole = decodedToken.role_name;
      this.loginUid = decodedToken.login_uid;
      this.email = decodedToken.user_email;
      localStorage.setItem("userRole", this.userRole);
      localStorage.setItem("tenantId", this.tenantId);
      localStorage.setItem("loginUid", this.loginUid);
      localStorage.setItem("UserRoleID", this.userRoleId);
      localStorage.setItem("email", this.email);
      this.menuData = decodedToken?.policies;
      this.enabledMenus = this.commonService.enableMenus(this.menuData);
      
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }

  setUserType(): void {
    const isStoreUserMenuPresent = ['cases', 'tasks', 'store-insights'].some(menu => Array.isArray(this.menuData[menu]) && this.menuData[menu].length > 0);
    const isCSOrgAdminMenuPresent = ['orgs', 'stores'].some(menu => Array.isArray(this.menuData[menu]) && this.menuData[menu].length > 0);
    this.isStoreUser = isStoreUserMenuPresent;
    this.isCSOrgAdmin = isCSOrgAdminMenuPresent;

    // Redirect based on user type
    console.log('this.router.url', this.router.url);
    if (this.isStoreUser && this.router.url === "/") {
      this.router.navigate(["/my-tasks"]);
    } else if (this.isCSOrgAdmin && this.router.url === "/") {
      this.router.navigate(["/configuration"]);
    }
  }

  isMenuEnabled(menu: string): boolean {
    if (this.isStoreUser) {
      return this.enabledMenus[menu] && (
        menu === 'cases' || menu === 'tasks' || menu === 'store-insights'
      );
    } else if (this.isCSOrgAdmin) {
      return this.enabledMenus[menu] && (
        menu === 'orgs' || menu === 'stores' || menu === 'cameras' || menu === 'devices' || menu === 'alerts' || menu === 'usecase-config'
      );
    }
    return false;
  }

  logout() {
    this.authService.logout();
    this.commonService.setCheckForLogout(true);
    this.commonService.resetOrgStoreValues();
  }
}
